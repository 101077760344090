import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormTextField from "../../forms/FormTextField";
import {
  getReceipe,
  getTagsAction,
  saveNewRecipe,
  saveRecipePhoto,
} from "../../store/actions/dashboardAction";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

const schema = yup.object({
  title: yup.string().trim().required("Cannot be empty"),
  bm2_ingredients: yup.string().trim().required("Cannot be empty"),
  bm2_preparation: yup.string().trim().required("Cannot be empty"),
});

const AddRecipeForm = ({ setPage, page }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [img, setImg] = useState("");
  const [tags, setTags] = useState([]);
  const [category, setCategory] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [mediaId, setMediaId] = useState(0);
  const [savingMedia, setSavingMedia] = useState(false);

  const { categories, recipeTags } = useSelector((state) => state.dashboard);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // useEffect(() => {
  //   dispatch(getTagsAction());
  // }, []);

  const saveData = async (data) => {
    if (!category) {
      return setCategoryError("Cannot be empty");
    }

    if (!mediaId) {
      return setError("Upload recipe image");
    }


    // if (!tags?.length) {
    //   return setError("Please select at least 1 tag");
    // } else {
    //   setError("");
    // }
    setError("");

    const payload = {
      title: data?.title,
      status: "pending",
      featured_media: mediaId || "",
      recipes_cat: [
        category ? +category : categories?.length ? categories[0]?.id : 0,
      ],
      recipes_tag: tags,
      meta: {
        bm2_ingredients: data?.bm2_ingredients,
        bm2_preparation: data?.bm2_preparation,
        bm2_ideas: data?.bm2_ideas,
        bm2_youtube: data?.bm2_youtube,
      },
    };
    setLoading(true);
    const res = await dispatch(saveNewRecipe(payload));
    setLoading(false);
    if (res?.status === "success") {
      setPage("success");
      dispatch(getReceipe());
    }
    if (res?.status !== "success") {
      setError("Sorry, you are not allowed to create posts as this user.");
      setTimeout(() => {
        setError("");
      }, [3000]);
    }
  };

  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setImg(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  const uploadImage = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      getBase64(e.target.files[0]);
      var data = new FormData();
      data.append("recipePhoto", e.target.files[0]);
      console.log("before save recie", data);
      setSavingMedia(true)
      const res = await saveRecipePhoto(data);
      setSavingMedia(false)

      if (res.mediaId) {
        setMediaId(res.mediaId);
      }
    }
  };

  const setPostTags = (val) => {
    if (tags.includes(val)) {
      setTags(tags.filter((int) => int !== val));
    } else {
      setTags((oldVal) => [...oldVal, val]);
    }
  };

  return (
    <div className="flex-grow-1 container-p-b">
      <div className="card shadow-none main-card mt-3">
        <div className="card-body">
          <div className="row g-3 flex-reverse-mob ">
            {page === "success" ? (
              <>
                <div className="col-md-2"></div>
                <div
                  className="col-md-6"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    paddingTop: 120,
                    paddingBottom: 80,
                  }}>
                  <h4
                    style={{
                      fontSize: 20,
                      textAlign: "center",
                      fontWeight: "600",
                      color: "#333",
                      marginBottom: 5,
                    }}>
                    Your recipe has been submitted!
                  </h4>

                  <button
                    className="btn w-50 btn-lg br-10 mt-4"
                    onClick={() => setPage("listing")}>
                    Close
                  </button>
                </div>
              </>
            ) : (
              <div
                className="col-md-12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "",
                }}>
                <div className="card add-recipe-form">
                  <div className="modal-header">
                    <button
                      type="button"
                      onClick={() => setPage("listing")}
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close">
                      <i className="bx bx-plus bx-rotate-45"></i>
                    </button>
                  </div>
                  <div className="card-header pb-3">
                    <h5 className="fw-semibold">Add New Recipe</h5>
                  </div>
                  <div className="card-body text-body">
                    <div className="row">
                      <div className="col-md-4">
                        <label className="fb-label">Image</label>
                      </div>
                      <div className="col-md-8">
                        <div
                          className={`${img ? "" : "card"}`}
                          style={{ height: "250px" }}>
                          <div className="card-body text-body">
                            {img ? (
                              <>
                                <a
                                  role="button"
                                  style={{
                                    color: "red",
                                    right: 20,
                                    position: "absolute",
                                  }}
                                  onClick={(e) => {
                                    setImg("");
                                  }}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24">
                                    <path
                                      fill="currentColor"
                                      d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
                                    />
                                  </svg>
                                </a>
                                <img
                                  style={{
                                    maxWidth: "100%",
                                    maxHeight: 250,
                                  }}
                                  src={img}
                                />
                              </>
                            ) : (
                              <label
                                for="upload"
                                className="file-upload mb-0"
                                tabindex="0">
                                <i className="bx bx-plus-circle bx-lg primary-color mb-3"></i>
                                <input
                                  type="file"
                                  id="upload"
                                  className="account-file-input"
                                  accept="image/png, image/jpeg, image/jpg, image/heic"
                                  hidden="true"
                                  onChange={uploadImage}
                                />

                                <p className="text-muted h5 semi mb-0">
                                  Upload Recipe Image
                                </p>
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <form
                      style={{ marginTop: 40 }}
                      className="d-flex flex-column justify-content-between"
                      onSubmit={handleSubmit(saveData)}>
                      <div>
                        <FormTextField
                          label="Title"
                          fieldName="title"
                          register={register}
                          errors={errors}
                        />
                        <div className="row mb-3 align-items-center">
                          <label className="col-md-4 sm-mb-1 col-sm-12 fb-label">
                            Category
                          </label>
                          <div className="col-md-8 col-sm-12">
                            <select
                              className="form-select form-control form-control-sm bg-white"
                              onChange={(e) => {
                                setCategory(e.target.value);
                                setCategoryError("");
                              }}
                              style={{ color: "#333" }}>
                              <option
                                selected
                                disabled>
                                Select Category
                              </option>
                              {categories?.map((category) => (
                                <option value={category?.id}>
                                  {category.name}
                                </option>
                              ))}
                            </select>
                            <p
                              style={{
                                color: "red",
                                fontSize: "12px",
                                marginTop: 4,
                                marginBottom: 1,
                              }}>
                              {categoryError ? categoryError : ""}
                            </p>
                          </div>
                        </div>
                        <FormTextField
                          label="Ingredients"
                          fieldName="bm2_ingredients"
                          register={register}
                          errors={errors}
                        />
                        <FormTextField
                          label="Preparation"
                          fieldName="bm2_preparation"
                          register={register}
                          errors={errors}
                        />
                        <FormTextField
                          label="Optional Ideas"
                          fieldName="bm2_ideas"
                          register={register}
                          errors={errors}
                        />
                        {/* <FormTextField
                          label="Youtube"
                          fieldName="bm2_youtube"
                          register={register}
                          errors={errors}
                        /> */}

                        {/* <div className="row mb-3 align-items-center">
                          <label className="col-4 fb-label">Tags</label>
                          <div className="form-block col-8">
                            <div className="pp-type-2 d-flex align-items-center flex-wrap">
                              {recipeTags?.map((tag) => (
                                <div
                                  className="form-check"
                                  for="Art">
                                  <input
                                    className="form-check-input"
                                    name="interests"
                                    type="checkbox"
                                    checked={tags.includes(tag?.id)}
                                    id="Art"
                                  />
                                  <label
                                    className="form-check-label"
                                    onClick={() => setPostTags(tag?.id)}>
                                    {tag?.name}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div> */}
                      </div>

                      <div className="row justify-content-center mb-4 mt-4">
                        <div className="col-sm-5">
                        {!savingMedia &&
                          <button
                            disabled={loading}
                            type="submit"
                            className="btn br-10 w-100">
                            {loading ? "Saving" : "Save"}
                          </button>
                        }

                        {savingMedia &&
                        <span><b>Uploading image...</b></span>
                        }
                        </div>
                      </div>
                      <p
                        style={{
                          marginTop: -10,
                          color: "red",
                          fontSize: "14px",
                          textAlign: "center",
                          paddingBottom: -20,
                        }}>
                        {error ? error : ""}
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddRecipeForm;
