import React, { useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    overflow: "hidden",
    boxShadow: "0 2px 20px 0 rgba(67, 89, 113, 0.45)",
  },

  overlay: {
    zIndex: 100000,
  },
};

const VideoTutorial = ({ modalIsOpen, afterOpenModal, closeModal }) => {  

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}>
          <h5 className="mb-2 bold"></h5>
          <a
            style={{
              fontWeight: 500,
              fontSize: 18,
              color: "#282828",
              zIndex: 10000,
              fontWeight: "bold",
              marginTop: -10,
            }}
            role="button"
            onClick={closeModal}>
            X
          </a>
        </div>
        <div className="video-tutorial">
          <h5 className="fw-semibold">
            Welcome to Your Personal Online Dashboard!
          </h5>
          <p className="text-video">
            Navigate your way to success with this quick video walkthrough.
          </p>
          <iframe
            className="video-tutorial-iframe"
            frameborder="0"
            allow="fullscreen; picture-in-picture"
            style={{
              top: 0,
              left: 0,
              width: "100%",
              marginTop: 10,
            }}
            src={              
              "https://dyhyg6r6fdehc.cloudfront.net/btm2/client-dashboard-walkthrough.mp4"
            }
          />
        </div>
      </Modal>
    </div>
  );
};

export default VideoTutorial;
