export const inputValidator = {
  emailRegx:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  zipRegx: /^[0-9]{5}(?:-[0-9]{4})?$/,
  phoneRegx: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
  nameRegx: /^[a-zA-Z ]*$/,
  noSpecialCharRegx:
    /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/,
};

export const setLocalData = (key, data) => {
  localStorage.setItem(key, data);
};

export const getLocalData = (key) => {
  return localStorage.getItem(key);
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export function getBase64(file) {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    console.log('file data');
  };
  reader.onerror = function (error) {
    console.log("Error: ", error);
  };
}

export const compareDates = (d2) => {
  let date1 = new Date().getTime();
  let date2 = new Date(d2).getTime();

  if (date1 < date2) {
    return false;
  } else {
    return true;
  }
};

export const allWeeks = {
  week1: "",
  week2: "",
  week3: "",
  week4: "",
  week5: "",
  week6: "",
  week7: "",
  week8: "",
  week9: "",
  week10: "",
  week11: "",
  week12: "",
  week13: "",
  week14: "",
  week15: "",
  week16: "",
  week17: "",
  week18: "",
};

export const allWeeksActions = {
  breakthroughBasics: [
    {
      week: "b1",
      actionSteps: "",
      videos: "",
    },
    {
      week: "b2",
      actionSteps: "",
      videos: "",
    },
    {
      week: "b3",
      actionSteps: "",
      videos: "",
    },
    {
      week: "b4",
      actionSteps: "",
      videos: "",
    },
  ],
  step1: [
    {
      week: "1a",
      actionSteps: "",
      videos: "",
      bonusSteps: "",
    },
    {
      week: "1aa",
      actionSteps: "",
      videos: "",
      bonusSteps: "",
    },
    {
      week: "1ab",
      actionSteps: "",
      videos: "",
      bonusSteps: "",
    },
    {
      week: "1ac",
      actionSteps: "",
      videos: "",
      bonusSteps: "",
    },
    {
      week: "1ad",
      actionSteps: "",
      videos: "",
      bonusSteps: "",
    },
    {
      week: "1ae",
      actionSteps: "",
      videos: "",
      bonusSteps: "",
    },
    {
      week: "1b",
      actionSteps: "",
      videos: "",
      bonusSteps: "",
    },
  ],
  step2: [
    {
      week: 2,
      actionSteps: "",
      videos: "",
      bonusSteps: "",
    },
    {
      week: 3,
      actionSteps: "",
      videos: "",
      bonusSteps: "",
    },
    {
      week: 4,
      actionSteps: "",
      videos: "",
      bonusSteps: "",
    },
    {
      week: 5,
      actionSteps: "",
      videos: "",
      bonusSteps: "",
    },
    {
      week: 6,
      actionSteps: "",
      videos: "",
      bonusSteps: "",
    },
    {
      week: 7,
      actionSteps: "",
      videos: "",
      bonusSteps: "",
    },
    {
      week: 8,
      actionSteps: "",
      videos: "",
      bonusSteps: "",
    },
    {
      week: 9,
      actionSteps: "",
      videos: "",
      bonusSteps: "",
    },
    {
      week: 10,
      actionSteps: "",
      videos: "",
      bonusSteps: "",
    },
    {
      week: 11,
      actionSteps: "",
      videos: "",
      bonusSteps: "",
    },
    {
      week: 12,
      actionSteps: "",
      videos: "",
      bonusSteps: "",
    },
    {
      week: 13,
      actionSteps: "",
      videos: "",
      bonusSteps: "",
    },
  ],
  step3: [
    {
      week: 14,
      actionSteps: "",
      videos: "",
      bonusSteps: "",
    },
  ],
  step4: [
    {
      week: 15,
      actionSteps: "",
      videos: "",
      bonusSteps: "",
    },
    {
      week: 16,
      actionSteps: "",
      videos: "",
      bonusSteps: "",
    },
    {
      week: 17,
      actionSteps: "",
      videos: "",
    },
    {
      week: 18,
      actionSteps: "",
      videos: "",
    },
  ],
};

export const sendEmailOptions = [
  {
    templateID: "success_scream",
    templateName: "Success Scream Interview email",
  },
  {
    templateID: "ultimate_member",
    templateName: "Ultimate member email",
  },
  {
    templateID: "too_expensive",
    templateName: "Your product is too expensive",
  },
  {
    templateID: "nan_finished_client",
    templateName: "Nan's calendar email to finished clients",
  },
  {
    templateID: "selling_aminos",
    templateName: "selling aminos as a supplement purchase",
  },
  {
    templateID: "chemo_detox_tips",
    templateName: "Chemotherapy detox tips",
  },
  {
    templateID: "ncs_upcharge",
    templateName: "Required Up-charge to a NCS",
  },
  {
    templateID: "missed_phone_1",
    templateName: "Missed Phone Consult",
  },
  {
    templateID: "missed_phone_2",
    templateName: "Missed Phone consult #2",
  },
  {
    templateID: "missed_phone_3",
    templateName: "Missed Phone consult #3",
  },
  {
    templateID: "coach_missed_call",
    templateName: "Missed first phone call email",
  },
  {
    templateID: "no_drops_protocol",
    templateName: "Having no drops protocol",
  },
  {
    templateID: "healthy_high_fat",
    templateName: "Healthy High Fat Email",
  },
  {
    templateID: "constipation",
    templateName: "Constipation",
  },
  {
    templateID: "colonoscopy",
    templateName: "Colonoscopy protocol",
  },
  {
    templateID: "gallbladder",
    templateName: "Gallbladder Protocol",
  },
  {
    templateID: "no_loss_no_coach",
    templateName: "NO Coach - Troubleshooting No weight loss",
  },
  {
    templateID: "no_loss_coach_linda",
    templateName: "Coach Linda - Troubleshooting No weight loss",
  },
  {
    templateID: "no_loss_coach_mary",
    templateName: "Coach Mary - Troubleshooting No weight loss",
  },
  {
    templateID: "restarting",
    templateName: "Restarting email",
  },
  {
    templateID: "nadine_confirm_pc",
    templateName: "Nadine's confirmation PC email",
  },
  {
    templateID: "first_time_purchase",
    templateName: "First time purchase - touch base",
  },
  {
    templateID: "restarting_ncs",
    templateName: "Restarting - NCS Touch Base email",
  },
  {
    templateID: "ingredient_list",
    templateName: "Ingredient list email",
  },
  {
    templateID: "referral_bank_check",
    templateName: "Referral Credit BANK CHECK",
  },
];
