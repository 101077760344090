import React, { useState } from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    height: 170,
    overflow: "hidden",
    boxShadow: "0 2px 20px 0 rgba(67, 89, 113, 0.45)",
  },
  overlay: {
    zIndex: 100000,
  },
};

const DeleteModal = ({
  modalIsOpen,
  afterOpenModal,
  closeModal,
  deleteHandler,
  isLoading,
}) => {
  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}>
          <p></p>
          <a
            style={{
              fontWeight: 500,
              fontSize: 18,
              color: "#282828",
              zIndex: 10000,
            }}
            role="button"
            onClick={closeModal}>
            X
          </a>
        </div>
        <div className="authentication-wrapper delete-modal">
          <div className="form-wizard">
            <h5
              style={{
                fontSize: 24,
                textAlign: "center",
                fontWeight: "600",
                color: "#333",
              }}>
              Confirm Delete
            </h5>
            <div
              className="row"
              style={{
                width: "350px",
                marginTop: "-30px",
                display: "flex",
                justifyContent: "space-between",
              }}>
              <button
                onClick={closeModal}
                disabled={isLoading}
                className="btn mt-5 wizard-next-btn grey-btn delete-btn col-md-6">
                Cancel
              </button>
              <button
                onClick={deleteHandler}
                disabled={isLoading}
                className="btn mt-5 wizard-next-btn delete-btn col-md-6">
                {isLoading ? "Processing" : "Delete"}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteModal;
