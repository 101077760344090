import React, { useEffect, useState } from "react";
import AddPosts from "../../modals/add-posts";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePostAction,
  getPosts,
  postReact,
  removeReaction,
  updatePrivacy,
} from "../../store/actions/dashboardAction";
import AddComment from "../../modals/add-comment";
import { FacebookShareButton } from "react-share";
import { getLocalData } from "../../utils/functions";
import ConfirmationModal from "../../modals/confirmation-modal";

const Posts = ({ loginUser, history }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenComment, setIsOpenComment] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [posts, setPosts] = useState([]);
  const [allPosts, setAllPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirm, setShowCofirm] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [loading, setLoading] = useState(false);

  const userType = getLocalData("userType");

  const [payload, setPayload] = useState({
    type: "all",
    category: 0,
    onlyMyPosts: 0,
  });

  const postCategories = [
    "None",
    "Milestone",
    "Kitchen Tip",
    "Food/Recipe Tip",
    "Nonscale Victory",
    "Aha Moment",
  ];
  const postCategoryIcons = [
    "",
    "bx bx-dumbbell me-1",
    "bx bx-knife me-1",
    "bx bx-bowl-hot me-1",
    "bx bx-dumbbell me-1",
    "bx bx-bulb me-1",
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    dispatch(getPosts(payload)).then((data) => {
      setPosts(data?.slice(0, 5));
      setAllPosts(data);
      setIsLoading(false);
    });
  }, [payload]);

  useEffect(() => {
    if (currentPage > 1) setPosts(allPosts?.slice(0, currentPage * 5));
  }, [currentPage]);

  useEffect(() => {
    if (window.location?.search?.includes("postId")) {
      const postId = window.location?.search?.split("=")[1];

      if (postId && allPosts?.length) {
        setIsOpenComment(true);
        setSelectedPost(allPosts?.find((post) => post?.id == postId));
      }
    }
  }, [window.location?.search, allPosts]);

  const postReactHandler = async (postId, id) => {
    let allPosts = [...posts];
    allPosts.forEach(async (post) => {
      if (post.id == postId) {
        if (post?.userReactions?.includes(id)) {
          const index = post?.userReactions?.indexOf(id);
          if (id == 1) {
            post.hearts--;
          } else if (id == 2) {
            post.likes--;
          } else if (id == 3) {
            post.claps--;
          }
          post?.userReactions?.splice(index, 1);
          removeReactionHandler(postId, id);
        } else {
          if (id == 1) {
            post.hearts++;
          } else if (id == 2) {
            post.likes++;
          } else if (id == 3) {
            post.claps++;
          }
          post?.userReactions?.push(id);
          dispatch(
            postReact({
              postId,
              id,
            }),
          );
        }
      }
    });

    setPosts(allPosts);
  };

  const removeReactionHandler = (postId, id) => {
    const payload = {
      id,
      postId,
    };

    dispatch(removeReaction(payload));
  };

  const updatePrivacyHandler = (isPrivate, postId) => {
    const payload = { isPrivate, postId };

    let allPosts = [...posts];

    allPosts.forEach(async (post) => {
      if (post.id == postId) {
        post.isPrivate = isPrivate ? 1 : 0;
      }
    });

    setPosts(allPosts);

    dispatch(updatePrivacy(payload));
  };

  const handleDelete = async () => {
    setLoading(true);
    await dispatch(deletePostAction(selectedData?.id));
    dispatch(getPosts(payload)).then((data) => {
      setPosts(data?.slice(0, 5));
      setAllPosts(data);
      setLoading(false);
    });
  };

  return (
    <div className="container-xxl flex-grow-1 container-p-b">
      {loginUser?.isClient || userType === "admin" || userType === "coach" ? (
        <div
          className="card shadow-none main-card"
          style={{ backgroundColor: "", height: "99%" }}>
          <div className="card-body">
            <div
              className="row justify-content-center post-scroll"
              id="state-scroll"
              style={{ backgroundColor: "", height: "auto" }}>
              <div className="col-md-10 col-xl-9">
                <div className="row align-items-center mb-3 g-md-4 g-2">
                  <div className="col-md-8 text-muted d-flex align-items-center">
                    <div className="form-group col-4 col-md-3 me-2 me-xl-3">
                      <select
                        className="form-select form-control form-control-sm border bg-white"
                        onChange={(e) =>
                          setPayload({ ...payload, type: e.target.value })
                        }
                        style={{ color: "#333" }}>
                        <option value="all">All</option>
                        <option value="public">Public</option>
                        <option value="private">Private</option>
                      </select>
                    </div>

                    <div className="form-group col-4 col-md-3 me-2 me-xl-3">
                      <select
                        className="form-select form-control form-control-sm border bg-white"
                        onChange={(e) =>
                          setPayload({ ...payload, category: e.target.value })
                        }
                        style={{ color: "#333" }}>
                        <option value="0">All</option>
                        <option value="1">Milestone</option>
                        <option value="2">Kitchen Tip</option>
                        <option value="3">Food/Recipe Tip</option>
                        <option value="4">Nonscale Victory</option>
                        <option value="5">Aha Moment</option>
                      </select>
                    </div>

                    <div className="form-group col-4">
                      <div className="form-check form-switch">
                        <label
                          className="form-check-label ps-2"
                          for="flexSwitchCheckChecked">
                          My Posts
                        </label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckChecked"
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              onlyMyPosts: payload.onlyMyPosts === 0 ? 1 : 0,
                            })
                          }
                          checked={payload.onlyMyPosts}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 text-md-end">
                  
                      <a
                        onClick={() => setIsOpen(true)}
                        data-bs-toggle="modal"
                        data-bs-target="#addPostModal"
                        className="btn btn-outline-primary br-10"
                        style={{ height: 35, paddingTop: 7 }}>
                        + Post
                      </a>
                    
                  </div>
                </div>

                {posts.length == 0 && (
                  <div className="posts-block">
                    <p
                      style={{
                        fontSize: 18,
                        fontWeight: 700,
                        justifyContent: "center",
                        display: "flex",
                        paddingTop: 20,
                      }}>
                      {isLoading ? "Loading..." : "No Posts"}
                    </p>
                  </div>
                )}

                {posts?.map((post) => (
                  <div
                    className="posts-block"
                    style={{ position: "relative" }}>
                    <div className="row mb-md-3 mb-2 align-items-md-center">
                      <div
                        className="d-flex align-items-center col-md-3 mb-sm-0 mb-2"
                        style={{ backgroundColor: "" }}>
                        {(userType === "admin" ||
                          userType === "coach" ||
                          post?.userId == loginUser?.id) && (
                          <svg
                            onClick={() => {
                              setShowCofirm(true);
                              setSelectedData(post);
                            }}
                            style={{
                              position: "absolute",
                              cursor: "pointer",
                              right: 10,
                              top: 10,
                            }}
                            width="18"
                            height="18"
                            fill="red"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512">
                            <path d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z" />
                          </svg>
                        )}

                        {post?.userPic ? (
                          <img
                            src={post?.userPic}
                            alt="user"
                            className="me-2 rounded-pill"
                            height="45"
                            width="45"
                            style={{ objectFit: "cover" }}
                          />
                        ) : (
                          <a
                            className={`nav-link dropdown-toggle hide-arrow btn-circle user-default-pic`}
                            role="button"
                            data-bs-toggle="dropdown">
                            <i className="bx bxs-user"></i>
                          </a>
                        )}
                        <div className="">
                          <h6 className="mb-0">{post?.userName}</h6>
                          <div className="text-muted">
                            {moment(new Date(post?.time)).fromNow()}
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-md-8 ps-md-0"
                        style={{ backgroundColor: "" }}>
                        <h5 className="mb-1 px-2">{post?.text}</h5>
                      </div>
                    </div>
                    <div className="border-top social-share pt-md-3 pt-2 row">
                      <div className="post-share col-6">
                        <a
                          role="button"
                          onClick={() => postReactHandler(post?.id, 1)}
                          style={{
                            color: post?.userReactions?.includes(1)
                              ? "#088eac"
                              : "#999",
                            pointerEvents:
                              loginUser?.completedDays === 0 &&
                              loginUser?.completed !== 1 &&
                              "none",
                          }}>
                          <i className="bx bx-heart me-2"></i>
                          <span>{post?.hearts}</span>
                        </a>
                        &nbsp;
                        <a
                          role="button"
                          onClick={() => postReactHandler(post?.id, 2)}
                          style={{
                            color: post?.userReactions?.includes(2)
                              ? "#088eac"
                              : "#999",
                            pointerEvents:
                              loginUser?.completedDays === 0 &&
                              loginUser?.completed !== 1 &&
                              "none",
                          }}>
                          <i className="bx bx-like me-2"></i>
                          <span>{post?.likes}</span>
                        </a>
                        {/* &nbsp; */}
                        {/* <a
                        role="button"
                        onClick={() => postReactHandler(post?.id, 3)} >
                        <img
                          src="assets/img/ph_hands-clapping.svg"
                          className="me-2"
                          style={{ paddingRight: 0, marginRight: 0, height: 18 }} />
                        <span style={{ paddingLeft: 0, marginLeft: 0 }}>{post?.claps}</span>
                      </a> */}
                        &nbsp;
                        <a
                          role="button"
                          onClick={() => {
                            setIsOpenComment(true);
                            setSelectedPost(post);
                          }}
                          data-bs-toggle="modal"
                          data-bs-target="#commentModal"
                          style={{
                            color: "#999",
                            pointerEvents:
                              loginUser?.completedDays === 0 &&
                              loginUser?.completed !== 1 &&
                              "none",
                          }}>
                          <i className="bx bx-message-rounded me-2"></i>
                          <span>{post?.comments}</span>
                        </a>
                      </div>
                      <div className="post-type col-6 justify-content-end text-muted d-flex align-items-center">
                        {post?.isPrivate === 1 &&
                          localStorage.userId == post.userId && (
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                updatePrivacyHandler(false, post?.id)
                              }>
                              <i className="bx bx-lock me-1"></i>
                              <span>Private</span>
                            </div>
                          )}
                        {post?.isPrivate === 0 &&
                          localStorage.userId == post.userId && (
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                updatePrivacyHandler(true, post?.id)
                              }>
                              <i className="bx bxs-lock-open me-1"></i>
                              <span>Public</span>
                            </div>
                          )}

                        {post?.isPrivate === 1 &&
                          localStorage.userId != post.userId && (
                            <div>
                              <i className="bx bx-lock me-1"></i>
                              <span>Private</span>
                            </div>
                          )}
                        {post?.isPrivate === 0 &&
                          localStorage.userId != post.userId && (
                            <div>
                              <i className="bx bxs-lock-open me-1"></i>
                              <span>Public</span>
                            </div>
                          )}
                        <div>
                          {post?.category == 4 ? (
                            <img
                              style={{ height: 15, marginRight: 5 }}
                              src="assets/img/biceps.png"
                            />
                          ) : (
                            <i
                              className={
                                post ? postCategoryIcons[post.category] : ""
                              }></i>
                          )}
                          <span>
                            {post ? postCategories[post.category] : ""}
                          </span>
                        </div>
                        <div className="dropdown">
                          {loginUser?.id === post?.userId &&
                            post?.isPrivate === 0 && (
                              <FacebookShareButton
                                url="https://alulawellness.com/"
                                quote={post?.text}
                                title={post?.text}
                                description={post?.text}
                                className="d-block">
                                <a
                                  role="button"
                                  className="text-muted"
                                  id="dropdownMenuLink"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false">
                                  <i className="bx bxs-share bx-flip-horizontal me-1"></i>
                                  <span>Share</span>
                                </a>
                              </FacebookShareButton>
                            )}
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuLink">
                            <li>
                              <a
                                className="dropdown-item"
                                href="#!">
                                <i className="bx bxl-facebook me-2"></i>
                                <span>Share on Facebook</span>
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="#!">
                                <i className="bx bx-link-alt bx-rotate-45 me-2"></i>
                                <span>Copy link</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div
                className="col-md-12 text-center py-3"
                style={{ color: "#088EAC" }}>
                {currentPage * 5 < allPosts?.length ? (
                  <a
                    role="button"
                    onClick={() => setCurrentPage(currentPage + 1)}
                    className="semi h6">
                    Load More Posts <i className="bx bx-chevrons-down ms-2"></i>
                  </a>
                ) : (
                  allPosts?.length > 0 && (
                    <p className="semi h6">All Posts Loaded</p>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="card mb-3">
          <p
            style={{
              fontSize: 18,
              margin: "auto",
              paddingTop: 45,
              height: 120,
              textAlign: "center",
            }}
            className="card-header pb-3 fw-semibold">
            Content will be displayed here once your program begins.
          </p>
        </div>
      )}
      <AddPosts
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        payload={payload}
        loginUser={loginUser}
        closeModal={() => setIsOpen(false)}
        setPosts={setPosts}
        setAllPosts={setAllPosts}
      />

      <AddComment
        modalIsOpen={modalIsOpenComment}
        setIsOpen={setIsOpenComment}
        payload={payload}
        loginUser={loginUser}
        closeModal={() => {
          history.push("/kudos-board");
          setIsOpenComment(false);
        }}
        selectedPost={selectedPost}
        setPosts={setPosts}
        setAllPosts={setAllPosts}
        currentPage={currentPage}
      />
      <ConfirmationModal
        modalIsOpen={showConfirm}
        setIsOpen={setShowCofirm}
        onClickHandler={handleDelete}
        isLoading={loading}
        btnClicked={8}
        closeModal={() => {
          setShowCofirm(false);
        }}
        key={8}
      />
    </div>
  );
};

export default Posts;
