import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { getLocalData, setLocalData } from "../utils/functions";
import moment from "moment";
import DateInput from "../forms/DateInput";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    background: "transparent",
  },
  overlay: {
    zIndex: 1010,
    // backgroundColor: "#1E2A42",
  },
};

const HighFatDate = ({ modalIsOpen, afterOpenModal, closeModal }) => {
  const [date, setDate] = useState(
    moment(getLocalData("high_fat_date"))?.format("MM/DD/YYYY"),
  );

  useEffect(() => {
    if (getLocalData("high_fat_date")) {
      setDate(moment(getLocalData("high_fat_date"))?.format("MM/DD/YYYY"));
    }
  }, [getLocalData("high_fat_date")]);

  const onChangeHandler = (e) => {
    setDate(moment(e.target.value)?.format("MM/DD/YYYY"));

    setLocalData("high_fat_date", e.target.value);
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Add Post">
      <div className="modal-content modal-content-date">
        <div className="modal-body">
          <div className="d-flex flex-column justify-content-between h-100">
            <div className="row">
              <div className="col-md-6 mt-3">
                <div className="col-md-12">
                  <label className="col-12 fb-label">
                    What will be your first high fat date?
                  </label>
                  <div className="col-md-12">
                    {/* <input
                      type="date"
                      className="form-control"
                      value={selectedDate}
                      onChange={onChangeHandler}
                    /> */}
                    <DateInput
                      date={date}
                      setDate={setDate}
                      highFatClickLocal={true}
                    />
                  </div>
                </div>
              </div>

              <p
                className="col-md-12"
                style={{
                  width: "95%",
                  padding: 10,
                  marginTop: 20,
                  marginLeft: "2%",
                  border: "1px solid #333",
                  fontWeight: 600,
                  color: "#088eac",
                }}>
                Thank you for choosing{" "}
                <span style={{ fontWeight: 700 }}>
                  {moment(date).format("MMMM Do, YYYY")}
                </span>{" "}
                as your first high-fat day. <br />{" "}
                <span style={{ fontWeight: 700 }}>
                  {moment(date).add(1, "days").format("MMMM Do, YYYY")}{" "}
                </span>{" "}
                will be your second high-fat day and{" "}
                <span style={{ fontWeight: 700 }}>
                  {moment(date).add(2, "days").format("MMMM Do, YYYY")}{" "}
                </span>{" "}
                will be your first low-calorie day. <br /> As a reminder, your
                weight loss drops begin on the two high fat days. Did we get
                those dates correct?
              </p>
            </div>

            <div className="modal-footer mt-4">
              <button
                className="btn w-100 btn-lg br-10"
                onClick={closeModal}>
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default HighFatDate;
