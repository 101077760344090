export const journeyData = {
  breakthroughBasics: [
    {
      week: "b1",
      actionSteps: ["Watch Breakthrough Basics videos 1 & 2"],
      videos: [
        {
          title: "Welcome to Breakthrough M2",
          link: "https://player.vimeo.com/video/849761866?h=a14d2163da&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        },
        {
          title: "History, Science, & Math",
          link: "https://player.vimeo.com/video/849761901?h=59a2f25bfe&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          bottomLinkText: "Dr. Simeons' Protocol",
          bottomLink:
            "https://alulawellness.com/wp-content/uploads/2018/02/DrSimeonsProtocol.pdf",
        },
      ],
    },
    {
      week: "b2",
      actionSteps: [
        "Watch Breakthrough Basics videos 3 & 4",
        "Connect on Team Breakthrough M2 on Facebook",
      ],
      videos: [
        {
          title: "The Little Known Secret About Fat",
          link: "https://player.vimeo.com/video/849761828?h=2f387de358&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        },
        {
          title: "Why are we different? Our Drops.",
          link: "https://player.vimeo.com/video/849760369?h=314d3d8891&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          bottomLinkText: "BTM2 Drops Ingredients List",
          bottomLink:
            "https://alulawellness.com/wp-content/uploads/2023/09/The-BTM2-Drops-Ingredients-PDF.pdf",
        },
      ],
    },
    {
      week: "b3",
      actionSteps: [
        "Watch Breakthrough Basics videos 5 & 6",
        "Follow us on any other Social Media Platforms you plug into",
      ],
      videos: [
        {
          title: "The 4 Steps to Success",
          link: "https://player.vimeo.com/video/855188316?h=4014e50854&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          bottomLinkText: "Fat Loss Food List",
          bottomLink:
            "https://alulawellness.com/wp-content/uploads/2023/09/Food-List-PDF.jpg",
        },
        {
          title: "A Day in The Diet Video",
          link: "https://player.vimeo.com/video/832390225?h=8d1b7366be",
        },
      ],
    },
    {
      week: "b4",
      actionSteps: ["Watch Breakthrough Basics videos 7 & 8"],
      videos: [
        {
          title: "Weight Loss Expectations ",
          link: "https://player.vimeo.com/video/850940848?h=6ffd3cb604&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        },
        {
          title: "It's Decision Time! ",
          link: "https://player.vimeo.com/video/853826423?h=6d89964d6b",
        },
      ],
    },
  ],
  step1: [
    {
      week: "1a",
      actionSteps: [
        "Watch Breakthrough videos 1 & 2",
        "With the guidance of your BTM2 Manual, add healthy fats to your meals this week.",
        "Calculate your water amount and practice drinking!.",
        "Get organized by using the inserts in your BTM2 Folder.",
      ],
      videos: [
        {
          title: "Action Steps for the 5 prep days",
          link: "https://player.vimeo.com/video/853057733?h=1bfc853b13&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        },
        {
          title: "Low Cal Basics",
          link: "https://player.vimeo.com/video/925078053?h=3e8f12858f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        },
      ],
      bonusSteps: [],
    },
    {
      week: "1aa",
      actionSteps: [
        "Watch Breakthrough videos 3 & 4",
        "Create your shopping list! Hint: A Shopping List Maker is in your BTM2 Folder.",
        "Organize your refrigerator and pantry for the BTM2 Program!",
      ],
      videos: [
        {
          title: "Food Lists FAQ's",
          link: "https://player.vimeo.com/video/925434100?h=b04b773be5&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        },
        {
          title: "Cooking Rules & Guidelines",
          link: "https://player.vimeo.com/video/925438282?h=156f3c0519&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        },
      ],
      bonusSteps: [],
    },
    {
      week: "1ab",
      actionSteps: [
        "Watch Breakthrough videos 5 & 6",
        "Print your Weekly Photo Stamps here.",
        "Holding your Prep Week Photo Stamp, take your first pictures, including front, back, and side views.",
        "Upload your starting photos to your BTM2 Dashboard.",
      ],
      videos: [
        {
          title: "Weight Loss Expectations",
          link: "https://player.vimeo.com/video/925442841?h=a85c33352c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        },
        {
          title: "Supplements for Low Cal and Beyond",
          link: "https://player.vimeo.com/video/925446581?h=c326cc6af6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        },
      ],
      bonusSteps: [],
    },
    {
      week: "1ac",
      actionSteps: [
        "Watch Breakthrough videos 7 & 8",
        "Schedule your Coach Call",
      ],
      videos: [
        {
          title: "Detoxification on Step 2",
          link: "https://player.vimeo.com/video/925450004?h=248b3d61f6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        },
        {
          title: "Exercise on Step 2 ",
          link: "https://player.vimeo.com/video/925452276?h=0125c39cb2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        },
      ],
      bonusSteps: [],
    },
    {
      week: "1ad",
      actionSteps: ["Watch Breakthrough videos 9 & 10"],
      videos: [
        {
          title: "Medications & Fat Loss",
          link: "https://player.vimeo.com/video/925454430?h=57ff2c81f9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        },
        {
          title: "Fat Flush w/Water Drinking",
          link: "https://player.vimeo.com/video/925463350?h=c68f06379e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        },
      ],
      bonusSteps: ["Subscribe to our social media profile"],
    },
    {
      week: "1ae",
      actionSteps: ["Watch Breakthrough videos 11 & 12"],
      videos: [
        {
          title: "Action Steps for the 2 High Fat Days",
          link: "https://player.vimeo.com/video/925466926?h=6632a351bf&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        },
        {
          title: "How to use the Weight Loss Drops",
          link: "https://player.vimeo.com/video/925472676?h=f3664a6e02&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        },
      ],
      bonusSteps: ["Subscribe to our social media profile"],
    },
    {
      week: "1b",
      actionSteps: [
        "Record your starting weight.",
        "Take the BTM2 Drops three times per day as directed in your BTM2 Manual.",
        "Eat a lot of high-fat food. Your BTM2 Manual has suggestions. Eat, Drink, and Be Merry!",
        "Take the BTM2 Drops three times per day as directed in your BTM2 Manual.",
        "Eat a lot of high-fat food. Your BTM2 Manual has suggestions. Eat, Drink, and Be Merry!",
        "Prep your 14-14-14 (proteins-vegetables-fr uits)",
        "Finish reading Steps 1 & 2 in your BTM2 Manual.",
        "Did you gain 3-5 during the 2 high fat days?",
      ],
      videos: [],
      bonusSteps: ["Subscribe to our social media profile"],
    },
  ],
  step2: [
    {
      week: 2,
      actionSteps: [
        "Use your Daily Habit Tracker.",
        "Holding your Weekly Photo Stamp, take your pictures, including front, back, and side views.",
        "Upload your weekly photos to your BTM2 Dashboard.",
        "Schedule your Coach Call and/or Attend a Group Coach Session.",
        "Take and record your inches.",
      ],
      videos: [
        {
          title: "Water Limits",
          link: "https://player.vimeo.com/video/897082313?h=a8eb1e9272&title=0&byline=0&portrait=0",
        },
        {
          title: "Group Coaching ",
          link: "https://player.vimeo.com/video/897288674?h=e9088088a9&title=0&byline=0&portrait=0",
        },
        {
          title: "Team BTM2",
          link: "https://player.vimeo.com/video/897289340?h=793cb2ea87&title=0&byline=0&portrait=0",
        },
        {
          title: "Referral Program ",
          link: "https://player.vimeo.com/video/897290003?h=33329e917d&title=0&byline=0&portrait=0",
        },
        {
          title: "Establish your Wellness Why",
          link: "https://player.vimeo.com/video/851614309?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          bottomLinkText: "BTM2 Weight Loss Why worksheet",
          bottomLink:
            "https://dashboard.alulawellness.com/breakthrough-uploads/BTM2-Weight-Loss-Why-worksheet.pdf",
        },
      ],
      bonusSteps: [],
    },
    {
      week: 3,
      actionSteps: [
        "Use your Daily Habit Tracker.",
        "Holding your Weekly Photo Stamp, take your pictures, including front, back, and side views.",
        "Upload your weekly photos to your BTM2 Dashboard.",
        "Schedule your Coach Call and/or Attend a Group Coach Session.",
        "Take and record your inches.",
      ],
      videos: [
        {
          title: "Water Limits",
          link: "https://player.vimeo.com/video/897082313?h=a8eb1e9272&title=0&byline=0&portrait=0",
        },
        {
          title: "Group Coaching ",
          link: "https://player.vimeo.com/video/897288674?h=e9088088a9&title=0&byline=0&portrait=0",
        },
        {
          title: "Team BTM2",
          link: "https://player.vimeo.com/video/897289340?h=793cb2ea87&title=0&byline=0&portrait=0",
        },
        {
          title: "Referral Program ",
          link: "https://player.vimeo.com/video/897290003?h=33329e917d&title=0&byline=0&portrait=0",
        },
        {
          title: "Establish your Wellness Why",
          link: "https://player.vimeo.com/video/851614309?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          bottomLinkText: "BTM2 Weight Loss Why worksheet",
          bottomLink:
            "https://dashboard.alulawellness.com/breakthrough-uploads/BTM2-Weight-Loss-Why-worksheet.pdf",
        },
      ],
      bonusSteps: [
        "Check out the BTM2 Kudos Board",
        "Did you define your Weight Loss Why?",
      ],
    },
    {
      week: 4,
      actionSteps: [
        "Use your Daily Habit Tracker.",
        "Holding your Weekly Photo Stamp, take your pictures, including front, back, and side views.",
        "Upload your weekly photos to your BTM2 Dashboard.",
        "Schedule your Coach Call and/or Attend a Group Coach Session.",
        "Take and record your inches.",
      ],
      videos: [
        {
          title: "Water Limits",
          link: "https://player.vimeo.com/video/897082313?h=a8eb1e9272&title=0&byline=0&portrait=0",
        },
        {
          title: "Group Coaching ",
          link: "https://player.vimeo.com/video/897288674?h=e9088088a9&title=0&byline=0&portrait=0",
        },
        {
          title: "Team BTM2",
          link: "https://player.vimeo.com/video/897289340?h=793cb2ea87&title=0&byline=0&portrait=0",
        },
        {
          title: "Referral Program ",
          link: "https://player.vimeo.com/video/897290003?h=33329e917d&title=0&byline=0&portrait=0",
        },
        {
          title: "Establish your Wellness Why",
          link: "https://player.vimeo.com/video/851614309?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          bottomLinkText: "BTM2 Weight Loss Why worksheet",
          bottomLink:
            "https://dashboard.alulawellness.com/breakthrough-uploads/BTM2-Weight-Loss-Why-worksheet.pdf",
        },
      ],
      bonusSteps: [
        "Check out the BTM2 Recipes and Videos",
        "Re-Read the weight loss expectations in your manual.  Are you within the averages?",
        "If you need to, bring this up on your next coaching call.",
      ],
    },
    {
      week: 5,
      actionSteps: [
        "Use your Daily Habit Tracker.",
        "Holding your Weekly Photo Stamp, take your pictures, including front, back, and side views.",
        "Upload your weekly photos to your BTM2 Dashboard.",
        "Schedule your Coach Call and/or Attend a Group Coach Session.",
        "Take and record your inches.",
      ],
      videos: [
        {
          title: "Water Limits",
          link: "https://player.vimeo.com/video/897082313?h=a8eb1e9272&title=0&byline=0&portrait=0",
        },
        {
          title: "Group Coaching ",
          link: "https://player.vimeo.com/video/897288674?h=e9088088a9&title=0&byline=0&portrait=0",
        },
        {
          title: "Team BTM2",
          link: "https://player.vimeo.com/video/897289340?h=793cb2ea87&title=0&byline=0&portrait=0",
        },
        {
          title: "Referral Program ",
          link: "https://player.vimeo.com/video/897290003?h=33329e917d&title=0&byline=0&portrait=0",
        },
        {
          title: "Establish your Wellness Why",
          link: "https://player.vimeo.com/video/851614309?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          bottomLinkText: "BTM2 Weight Loss Why worksheet",
          bottomLink:
            "https://dashboard.alulawellness.com/breakthrough-uploads/BTM2-Weight-Loss-Why-worksheet.pdf",
        },
      ],
      bonusSteps: [
        "Refer a friend?",
        "Check out the BTM2 Referral program and Referral Contest!",
        "Please Take our Coach Survey.",
        "Invite a friend to Team Breakthrough M2.",
      ],
    },
    {
      week: 6,
      actionSteps: [
        "Use your Daily Habit Tracker.",
        "Holding your Weekly Photo Stamp, take your pictures, including front, back, and side views.",
        "Upload your weekly photos to your BTM2 Dashboard.",
        "Schedule your Coach Call and/or Attend a Group Coach Session.",
        "Take and record your inches.",
      ],
      videos: [
        {
          title: "Water Limits",
          link: "https://player.vimeo.com/video/897082313?h=a8eb1e9272&title=0&byline=0&portrait=0",
        },
        {
          title: "Group Coaching ",
          link: "https://player.vimeo.com/video/897288674?h=e9088088a9&title=0&byline=0&portrait=0",
        },
        {
          title: "Team BTM2",
          link: "https://player.vimeo.com/video/897289340?h=793cb2ea87&title=0&byline=0&portrait=0",
        },
        {
          title: "Referral Program ",
          link: "https://player.vimeo.com/video/897290003?h=33329e917d&title=0&byline=0&portrait=0",
        },
        {
          title: "Establish your Wellness Why",
          link: "https://player.vimeo.com/video/851614309?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          bottomLinkText: "BTM2 Weight Loss Why worksheet",
          bottomLink:
            "https://dashboard.alulawellness.com/breakthrough-uploads/BTM2-Weight-Loss-Why-worksheet.pdf",
        },
      ],
      bonusSteps: [
        "Actions for Sustainability!",
        "Do something out of your comfort zone, post a pic on Team Breakthrough or the Kudos Board!",
      ],
    },
    {
      week: 7,
      actionSteps: [
        "Use your Daily Habit Tracker.",
        "Holding your Weekly Photo Stamp, take your pictures, including front, back, and side views.",
        "Upload your weekly photos to your BTM2 Dashboard.",
        "Schedule your Coach Call and/or Attend a Group Coach Session.",
        "Take and record your inches.",
      ],
      videos: [
        {
          title: "Water Limits",
          link: "https://player.vimeo.com/video/897082313?h=a8eb1e9272&title=0&byline=0&portrait=0",
        },
        {
          title: "Group Coaching ",
          link: "https://player.vimeo.com/video/897288674?h=e9088088a9&title=0&byline=0&portrait=0",
        },
        {
          title: "Team BTM2",
          link: "https://player.vimeo.com/video/897289340?h=793cb2ea87&title=0&byline=0&portrait=0",
        },
        {
          title: "Referral Program ",
          link: "https://player.vimeo.com/video/897290003?h=33329e917d&title=0&byline=0&portrait=0",
        },
        {
          title: "Establish your Wellness Why",
          link: "https://player.vimeo.com/video/851614309?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          bottomLinkText: "BTM2 Weight Loss Why worksheet",
          bottomLink:
            "https://dashboard.alulawellness.com/breakthrough-uploads/BTM2-Weight-Loss-Why-worksheet.pdf",
        },
      ],
      bonusSteps: [
        "Actions for Sustainability!",
        "Take pictures of you in some of your old clothes and post them!",
        "Then...box them up for donation!",
      ],
    },
    {
      week: 8,
      actionSteps: [
        "Use your Daily Habit Tracker.",
        "Holding your Weekly Photo Stamp, take your pictures, including front, back, and side views.",
        "Upload your weekly photos to your BTM2 Dashboard.",
        "Schedule your Coach Call and/or Attend a Group Coach Session.",
        "Take and record your inches.",
      ],
      videos: [
        {
          title: "Water Limits",
          link: "https://player.vimeo.com/video/897082313?h=a8eb1e9272&title=0&byline=0&portrait=0",
        },
        {
          title: "Group Coaching ",
          link: "https://player.vimeo.com/video/897288674?h=e9088088a9&title=0&byline=0&portrait=0",
        },
        {
          title: "Team BTM2",
          link: "https://player.vimeo.com/video/897289340?h=793cb2ea87&title=0&byline=0&portrait=0",
        },
        {
          title: "Referral Program ",
          link: "https://player.vimeo.com/video/897290003?h=33329e917d&title=0&byline=0&portrait=0",
        },
        {
          title: "Establish your Wellness Why",
          link: "https://player.vimeo.com/video/851614309?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          bottomLinkText: "BTM2 Weight Loss Why worksheet",
          bottomLink:
            "https://dashboard.alulawellness.com/breakthrough-uploads/BTM2-Weight-Loss-Why-worksheet.pdf",
        },
      ],
      bonusSteps: [
        "Actions for Sustainability!",
        "Check out this blog",
        "Check out this video",
      ],
    },
    {
      week: 9,
      actionSteps: [
        "Use your Daily Habit Tracker.",
        "Holding your Weekly Photo Stamp, take your pictures, including front, back, and side views.",
        "Upload your weekly photos to your BTM2 Dashboard.",
        "Schedule your Coach Call and/or Attend a Group Coach Session.",
        "Take and record your inches.",
      ],
      videos: [
        {
          title: "Water Limits",
          link: "https://player.vimeo.com/video/897082313?h=a8eb1e9272&title=0&byline=0&portrait=0",
        },
        {
          title: "Group Coaching ",
          link: "https://player.vimeo.com/video/897288674?h=e9088088a9&title=0&byline=0&portrait=0",
        },
        {
          title: "Team BTM2",
          link: "https://player.vimeo.com/video/897289340?h=793cb2ea87&title=0&byline=0&portrait=0",
        },
        {
          title: "Referral Program ",
          link: "https://player.vimeo.com/video/897290003?h=33329e917d&title=0&byline=0&portrait=0",
        },
        {
          title: "Establish your Wellness Why",
          link: "https://player.vimeo.com/video/851614309?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          bottomLinkText: "BTM2 Weight Loss Why worksheet",
          bottomLink:
            "https://dashboard.alulawellness.com/breakthrough-uploads/BTM2-Weight-Loss-Why-worksheet.pdf",
        },
      ],
      bonusSteps: [
        "Actions for Sustainability!",
        "Check out this blog",
        "Check out this video",
      ],
    },
    {
      week: 10,
      actionSteps: [
        "Use your Daily Habit Tracker.",
        "Holding your Weekly Photo Stamp, take your pictures, including front, back, and side views.",
        "Upload your weekly photos to your BTM2 Dashboard.",
        "Schedule your Coach Call and/or Attend a Group Coach Session.",
        "Take and record your inches.",
      ],
      videos: [
        {
          title: "Water Limits",
          link: "https://player.vimeo.com/video/897082313?h=a8eb1e9272&title=0&byline=0&portrait=0",
        },
        {
          title: "Group Coaching ",
          link: "https://player.vimeo.com/video/897288674?h=e9088088a9&title=0&byline=0&portrait=0",
        },
        {
          title: "Team BTM2",
          link: "https://player.vimeo.com/video/897289340?h=793cb2ea87&title=0&byline=0&portrait=0",
        },
        {
          title: "Referral Program ",
          link: "https://player.vimeo.com/video/897290003?h=33329e917d&title=0&byline=0&portrait=0",
        },
        {
          title: "Establish your Wellness Why",
          link: "https://player.vimeo.com/video/851614309?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          bottomLinkText: "BTM2 Weight Loss Why worksheet",
          bottomLink:
            "https://dashboard.alulawellness.com/breakthrough-uploads/BTM2-Weight-Loss-Why-worksheet.pdf",
        },
      ],
      bonusSteps: [
        "Actions for Sustainability!",
        "Check out this blog",
        "Check out this video",
      ],
    },
    {
      week: 11,
      actionSteps: [
        "Use your Daily Habit Tracker.",
        "Holding your Weekly Photo Stamp, take your pictures, including front, back, and side views.",
        "Upload your weekly photos to your BTM2 Dashboard.",
        "Schedule your Coach Call and/or Attend a Group Coach Session.",
        "Take and record your inches.",
      ],
      videos: [
        {
          title: "Water Limits",
          link: "https://player.vimeo.com/video/897082313?h=a8eb1e9272&title=0&byline=0&portrait=0",
        },
        {
          title: "Group Coaching ",
          link: "https://player.vimeo.com/video/897288674?h=e9088088a9&title=0&byline=0&portrait=0",
        },
        {
          title: "Team BTM2",
          link: "https://player.vimeo.com/video/897289340?h=793cb2ea87&title=0&byline=0&portrait=0",
        },
        {
          title: "Referral Program ",
          link: "https://player.vimeo.com/video/897290003?h=33329e917d&title=0&byline=0&portrait=0",
        },
        {
          title: "Establish your Wellness Why",
          link: "https://player.vimeo.com/video/851614309?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          bottomLinkText: "BTM2 Weight Loss Why worksheet",
          bottomLink:
            "https://dashboard.alulawellness.com/breakthrough-uploads/BTM2-Weight-Loss-Why-worksheet.pdf",
        },
      ],
      bonusSteps: [
        "Actions for Sustainability!",
        "Check out this blog",
        "Check out this video",
      ],
    },
    {
      week: 12,
      actionSteps: [
        "Use your Daily Habit Tracker.",
        "Holding your Weekly Photo Stamp, take your pictures, including front, back, and side views.",
        "Upload your weekly photos to your BTM2 Dashboard.",
        "Schedule your Coach Call and/or Attend a Group Coach Session.",
        "Take and record your inches.",
      ],
      videos: [
        {
          title: "Water Limits",
          link: "https://player.vimeo.com/video/897082313?h=a8eb1e9272&title=0&byline=0&portrait=0",
        },
        {
          title: "Group Coaching ",
          link: "https://player.vimeo.com/video/897288674?h=e9088088a9&title=0&byline=0&portrait=0",
        },
        {
          title: "Team BTM2",
          link: "https://player.vimeo.com/video/897289340?h=793cb2ea87&title=0&byline=0&portrait=0",
        },
        {
          title: "Referral Program ",
          link: "https://player.vimeo.com/video/897290003?h=33329e917d&title=0&byline=0&portrait=0",
        },
        {
          title: "Establish your Wellness Why",
          link: "https://player.vimeo.com/video/851614309?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          bottomLinkText: "BTM2 Weight Loss Why worksheet",
          bottomLink:
            "https://dashboard.alulawellness.com/breakthrough-uploads/BTM2-Weight-Loss-Why-worksheet.pdf",
        },
      ],
      bonusSteps: [
        "Actions for Sustainability!",
        "Check out this blog",
        "Check out this video",
      ],
    },
    {
      week: 13,
      actionSteps: [
        "Use your Daily Habit Tracker.",
        "Holding your Weekly Photo Stamp, take your pictures, including front, back, and side views.",
        "Upload your weekly photos to your BTM2 Dashboard.",
        "Schedule your Coach Call and/or Attend a Group Coach Session.",
        "Take and record your inches.",
      ],
      videos: [
        {
          title: "Water Limits",
          link: "https://player.vimeo.com/video/897082313?h=a8eb1e9272&title=0&byline=0&portrait=0",
        },
        {
          title: "Group Coaching ",
          link: "https://player.vimeo.com/video/897288674?h=e9088088a9&title=0&byline=0&portrait=0",
        },
        {
          title: "Team BTM2",
          link: "https://player.vimeo.com/video/897289340?h=793cb2ea87&title=0&byline=0&portrait=0",
        },
        {
          title: "Referral Program ",
          link: "https://player.vimeo.com/video/897290003?h=33329e917d&title=0&byline=0&portrait=0",
        },
        {
          title: "Establish your Wellness Why",
          link: "https://player.vimeo.com/video/851614309?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          bottomLinkText: "BTM2 Weight Loss Why worksheet",
          bottomLink:
            "https://dashboard.alulawellness.com/breakthrough-uploads/BTM2-Weight-Loss-Why-worksheet.pdf",
        },
      ],
      bonusSteps: [
        "Actions for Sustainability!",
        "Check out this blog",
        "Check out this video",
      ],
    },
  ],
  step3: [
    {
      week: 14,
      actionSteps: [
        "No BTM2 Drops",
        "Stay on the Step 2 low-cal meal plan.",
        "No BTM2 Drops",
        "Stay on the Step 2 low-cal meal plan.",
        "Begin watching Step 4 videos",
        "Read Steps 3 & 4 in your BTM2 Manual.",
      ],
      videos: [
        {
          title: "Step 3: Stabilize",
          link: "https://player.vimeo.com/video/854406160?h=f9e34702e4&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        },
        {
          title: "Step 3: Interim",
          link: "https://player.vimeo.com/video/854404754?h=e2e99496c7&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        },
      ],
    },
  ],
  step4: [
    {
      week: 15,
      actionSteps: [
        "Watch Step 4 Transition Videos 1 & 2",
        "Read the Step 4 section in your BTM2 Manual.",
        "Continue your daily water intake.",
        "Continue to record your daily weight.",
      ],
      videos: [
        {
          title: "Step 4: Transition Basics",
          link: "https://player.vimeo.com/video/854424141?h=e35f1cc567&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        },
        {
          title: "Step 4: The 1-2-3 Menu Plan",
          link: "https://player.vimeo.com/video/854727072?h=4b73df8d5b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        },
      ],
    },
    {
      week: 16,
      actionSteps: [
        "Watch Step 4 Transition Videos 3 & 4",
        "Finish reading the Step 4 section in your BTM2 Manual.",
        "Continue your daily water intake.",
        "Continue to record your daily weight.",
      ],
      videos: [
        {
          title: "Step 4: Healthy Habits & What to Watch For",
          link: "https://player.vimeo.com/video/854757141?h=884f5857b9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        },
        {
          title: "Step 4 and Beyond: Eating Right For Your Type",
          link: "https://player.vimeo.com/video/855190051?h=bf61131d77&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        },
      ],
    },
    {
      week: 17,
      actionSteps: [
        "Upload your Final Before-After Photo and Testimonial.",
        "Schedule your Success Scream.",
        "Give us a Google Review.",
        "Give us a Facebook Review.",
      ],
      videos: [
        {
          title: "Step 4: 3-week check-in",
          link: "https://player.vimeo.com/video/897290631?h=53913b7b45&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        },
        {
          title: "Step 4: Transition Seminar",
          link: "https://www.youtube.com/embed/LR5L9tdAP6E?si=InIGA6x4ssJieTow",
        }
      ],
    },
    {
      week: 18,
      actionSteps: ["Watch 90/10 Video & the message from our CEO!"],
      videos: [
        {
          title: "Step 5 - 90/10 Living",
          link: "https://player.vimeo.com/video/855191457?h=dc7aeccdd8&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        },
      ],
    },
  ],
};

export const journeyLables = {
  breakthroughBasics: "BTM2 Basics",
  step1: "Step 1 - Prep week",
  step2: "Step 2 - Fat Loss Phase",
  step3: "Step 3 - Stabilize",
  step4: "Step 4 - Transition",
};
