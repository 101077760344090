import React, { useEffect } from "react";
import RestartJourney from "../views/dashboard/RestartJourney";
import { useSelector } from "react-redux";

const ValidationModalUI = ({ setView, view, closeModal, history }) => {
  const { loginUser } = useSelector((state) => state.auth);

  useEffect(() => {
    setView("text");
  }, []);

  return (
    <div className={`${view === "text" ? "cheated-modal" : "restart-journey"}`}>
      {view === "text" && (
        <>
          <p className="cheated-modal-content">
            We are excited you are on your new dashboard! To gain full access,
            please choose one of the following:
          </p>{" "}
          <div className="cheated-modal-box">
            <button
              style={{ width: "90%", marginRight: 20, fontSize: 18 }}
              type="button"
              onClick={() =>
                window.open("https://alulawellness.com/checkout/intake-forms/")
              }
              className="btn w-100 br-10">
              I am totally new to BTM2
            </button>
            <button
              style={{ width: "90%", fontSize: 18 }}
              type="button"
              onClick={() => setView("form")}
              className="btn w-100 br-10">
              I am a former BTM2 client
            </button>
          </div>
        </>
      )}
      {view === "form" && (
        <RestartJourney
          loginUser={loginUser}
          history={history}
          setView={setView}
        />
      )}
    </div>
  );
};

export default ValidationModalUI;
