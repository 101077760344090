import React, { useEffect } from "react";
import { getLocalData } from "../utils/functions";

const PrivateLayout = ({ children, history }) => {
  const token = getLocalData("token");
  const userId = getLocalData("userId");

  useEffect(() => {
    if (!token || !userId) {
      sessionStorage.setItem("redirectUrl", window.location.pathname);
      history.push("/login");
    }
    // eslint-disable-next-line
  }, [token]);

  return <>{children}</>;
};

export default PrivateLayout;
