import moment from "moment";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setLocalData } from "../utils/functions";

const DateInput = ({ date, setDate, highFatClick, highFatClickLocal }) => {
  const [openDrop, setIsOpenDrop] = useState(false);

  return (
    <DatePicker
      value={date}
      onChange={(date) => {
        setDate(moment(date)?.format("MM/DD/YYYY"));
        highFatClick && highFatClick();
        highFatClickLocal &&
          setLocalData("high_fat_date", moment(date)?.format("MM/DD/YYYY"));
      }}
      onKeyDown={(e) => {
        e.preventDefault();
      }}
      onSelect={() => setIsOpenDrop(false)}
      dateFormat="MM/DD/YYYY"
      className="form-control"
      selected={new Date(date)?.getTime()}
      open={openDrop}
      showIcon
      onBlur={() => setIsOpenDrop(false)}
      onInputClick={() => setIsOpenDrop(true)}
      icon={
        <svg
          onClick={() => setIsOpenDrop(true)}
          style={{
            right: 0,
            fontSize: 18,
            marginTop: 7,
            cursor: "pointer",
          }}
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          fill="#333"
          viewBox="0 0 448 512">
          <path d="M400 64h-48V12c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v52H160V12c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v52H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zm-6 400H54c-3.3 0-6-2.7-6-6V160h352v298c0 3.3-2.7 6-6 6z" />
        </svg>
      }>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <a
          style={{
            fontWeight: 500,
            fontSize: 15,
            display: "block",
            color: "#088eac",
          }}
          role="button"
          onClick={() => {
            setDate(moment(new Date())?.format("MM/DD/YYYY"));
            setIsOpenDrop(false);
          }}>
          Select Current Date
        </a>
      </div>
    </DatePicker>
  );
};

export default DateInput;
