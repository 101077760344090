import React, { useState } from "react";
import Modal from "react-modal";
import ValidationModalUI from "./validation-modal-ui";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    padding: 15,
    paddingLeft: 0,
    paddingRight: 0,
    background: "transparent",
  },
  overlay: {
    zIndex: 100,
  },
};

const ValidationModal = ({
  modalIsOpen,
  afterOpenModal,
  closeModal,
  history,
}) => {
  const [view, setView] = useState("text");

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: -20,
            marginLeft: 20,
            marginRight: 20,
          }}>
          <p style={{ fontSize: 24, fontWeight: 600 }}></p>
          {/* <a
            style={{ fontWeight: 500, fontSize: 20, color: "#282828" }}
            role="button"
            onClick={view === "home" ? closeModal : () => setView("home")}>
            X
          </a> */}
        </div>
        <ValidationModalUI
          setView={setView}
          view={view}
          closeModal={closeModal}
          history={history}
        />
      </Modal>
    </div>
  );
};

export default ValidationModal;
