import React, { useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdminMembers,
  getCoachMembers,
  saveUserPlaneStartsOn,
} from "../store/actions/dashboardAction";
import { getLocalData } from "../utils/functions";
import FormDatePicker from "../forms/FormDatePicker";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    overflow: "visible",
    boxShadow: "0 2px 20px 0 rgba(67, 89, 113, 0.45)",
    width: 400,
  },

  overlay: {
    zIndex: 100000,
  },
};

const EditDate = ({
  modalIsOpen,
  afterOpenModal,
  closeModal,
  member,
  dateType,
  getUserInfo,
}) => {
  const [page, setPage] = useState("form");
  const [loading, setLoading] = useState(false);

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}>
          <h5 className="mb-2 bold"></h5>
          <a
            style={{
              fontWeight: 500,
              fontSize: 18,
              color: "#282828",
              zIndex: 10000,
              fontWeight: "bold",
              marginTop: -10,
            }}
            role="button"
            onClick={() => {
              closeModal();
              setPage("form");
            }}>
            X
          </a>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}>
          {page === "form" ? (
            <>
              <div
                className="form-group col-12 col-md-12 mt-4"
                style={{ marginLeft: 30 }}>
                <>
                  <label className="tag mb-2">
                    {dateType === "started-date"
                      ? "Step 1 Start Prep"
                      : dateType === "transition-date"
                      ? "Step 3 Start Stabilize"
                      : dateType === "step2-date"
                      ? "Step 2 Low Cal"
                      : dateType === "step4-date"
                      ? "Step 4 Start Transition"
                      : "Low Calorie"}{" "}
                    Date{" "}
                  </label>
                  {dateType === "started-date" && (
                    <FormDatePicker
                      id={member?.id}
                      planStartedOn={member?.planStartedOn}
                      planStartedOnForm={true}
                      setPage={setPage}
                      getUserInfo={getUserInfo}
                      member={member}
                      setLoading={setLoading}
                      loading={loading}
                    />
                  )}
                  {dateType === "low-calorie-date" && (
                    <FormDatePicker
                      id={member?.id}
                      lowCalorieDate={member?.lowCalorieDate}
                      lowCalorieDateForm={true}
                      setPage={setPage}
                      getUserInfo={getUserInfo}
                      member={member}
                      setLoading={setLoading}
                      loading={loading}
                    />
                  )}
                  {dateType === "transition-date" && (
                    <FormDatePicker
                      id={member?.id}
                      transitionDate={member?.transitionDate}
                      transitionDateForm={true}
                      setPage={setPage}
                      getUserInfo={getUserInfo}
                      member={member}
                      setLoading={setLoading}
                      loading={loading}
                    />
                  )}
                  {dateType === "step2-date" && (
                    <FormDatePicker
                      id={member?.id}
                      step2LowCalDate={member?.step2LowCalDate}
                      step2LowCalDateForm={true}
                      setPage={setPage}
                      getUserInfo={getUserInfo}
                      member={member}
                      setLoading={setLoading}
                      loading={loading}
                    />
                  )}
                  {dateType === "step4-date" && (
                    <FormDatePicker
                      id={member?.id}
                      step4TransitionDate={member?.step4TransitionDate}
                      step4TransitionDateForm={true}
                      setPage={setPage}
                      getUserInfo={getUserInfo}
                      member={member}
                      setLoading={setLoading}
                      loading={loading}
                    />
                  )}
                </>
              </div>
            </>
          ) : (
            <>
              <h5
                style={{
                  fontSize: 18,
                  textAlign: "center",
                  fontWeight: "600",
                  color: "#333",
                  marginBottom: 10,
                }}>
                {dateType === "started-date"
                  ? "Step 1 Start Prep"
                  : dateType === "transition-date"
                  ? "Step 3 Start Stabilize"
                  : dateType === "step2-date"
                  ? "Step 2 Low Cal"
                  : dateType === "step4-date"
                  ? "Step 4 Start Transition"
              : "Low Calorie"}{" "}
                date changed successfully!
              </h5>

              <button
                className="btn w-50 btn-lg br-10 mt-4"
                onClick={() => {
                  closeModal();
                  setPage("form");
                }}>
                Done
              </button>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default EditDate;
