import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allWeeksActions } from "../utils/functions";
import {
  getActions,
  saveCompletedActions,
  updateCompletedActions,
} from "../store/actions/dashboardAction";
import { journeyData } from "../utils/jorney-data";

const ActionSteps = ({ dailyNotesData }) => {
  const [actions, setActions] = useState(allWeeksActions);
  const [currentStep, setCurrentStep] = useState(null);
  const [currentWeek, setCurrentWeek] = useState(null);
  const [dailyNotes, setDailyNotes] = useState({});

  const dispatch = useDispatch();

  const { loginUser } = useSelector((state) => state.auth);

  useEffect(() => {
    if (loginUser?.contentDay) {
      let week = Math.ceil(loginUser?.contentDay / 7);

      let step = 1;
      if (loginUser?.contentDay <= 7) {
        step = 1;
        loginUser?.contentDay <= 5 ? (week = "1a") : (week = "1b");
      } else if (loginUser?.contentDay > 7 && loginUser?.contentDay <= 91) {
        step = 2;
      } else if (loginUser?.contentDay > 91 && loginUser?.contentDay <= 98) {
        step = 3;
      } else {
        step = 4;
      }

      if (step === 1) {
        if (loginUser?.contentDay <= 5) {
          setDailyNotes(journeyData[`step1`]?.[0]);
        } else {
          setDailyNotes(journeyData[`step1`]?.[1]);
        }
      } else {
        setDailyNotes(
          journeyData[`step${step}`]?.find((data) => data.week === week),
        );
      }

      setCurrentWeek(week);
      setCurrentStep(`step${step}`);
    }
  }, [loginUser?.contentDay]);

  useEffect(() => {
    getCompletedActions();
  }, []);

  const getCompletedActions = async (day) => {
    const resp = await dispatch(getActions());

    if (resp?.status === 404) {
      saveCompletedHandler();
    } else {
      if (resp?.completed_index) {
        setActions(resp?.completed_index);
      }
    }
  };

  const saveCompletedHandler = (step) => {
    const payload = {
      completed_index: actions,
    };

    dispatch(saveCompletedActions(payload));
  };

  const handleComplete = (index, key) => {
    const selectedAction = actions[currentStep]?.find(
      (val) => val.week === currentWeek,
    );

    if (!selectedAction) {
      return false;
    }

    const data = { ...actions };
    let str = selectedAction[key];
    selectedAction[key] = str?.includes(index)
      ? str?.includes(`${index},`)
        ? str?.replace(`${index},`, ``)
        : str?.includes(`,${index}`)
        ? str?.replace(`,${index}`, ``)
        : str?.replace(index, ``)
      : str?.concat(str?.length ? `,${index}` : index);

    setActions(data);
    const payload = {
      completed_index: data,
      all_complete: "",
    };
    dispatch(updateCompletedActions(payload));
  };

  const checkingChecked = (index, data) => {
    const selectedAction = actions[currentStep]?.find(
      (val) => val.week === currentWeek,
    );

    return selectedAction?.[data]?.includes(index);
  };

  return (
    <>
      {(dailyNotes?.actionSteps || dailyNotes?.bonusSteps?.length > 0) && (
        <>
          <div className="card">
            {dailyNotes?.actionSteps?.length > 0 ? (
              <>
                <h6
                  className="card-header pb-3 fw-semibold"
                  style={{
                    fontSize: 20,
                    fontWeight: "bolder",
                    textAlign: "center",
                    color: "#088eac",
                  }}>
                  Actions Steps:
                </h6>
                <div className="card-body text-body">
                  <ol className="list-unstyled">
                    {dailyNotes?.actionSteps?.map((step, i) => (
                      <li>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            marginBottom: -25,
                          }}>
                          <input
                            onClick={() => handleComplete(i, "actionSteps")}
                            checked={checkingChecked(i, "actionSteps")}
                            style={{
                              marginRight: 10,
                              cursor: "pointer",
                              borderRadius: "50%",
                              border: "1px solid #ccc",
                            }}
                            className="form-check-input form-check-input-primary col-md-1"
                            type="checkbox"
                          />
                        </div>
                        <p
                          style={{
                            paddingRight: 10,
                            paddingLeft: 35,
                            color: checkingChecked(i, actions) && "#088eac",
                          }}
                          className="col-md-11"
                          dangerouslySetInnerHTML={{
                            __html: step,
                          }}></p>{" "}
                      </li>
                    ))}
                  </ol>
                  {/* <div className="text-1e mt-2">
                <br />
                <h6>
                  {dailyNotesData?.endGreeting
                    ? dailyNotesData?.endGreeting
                    : "You’re doing this!"}
                </h6>
                <h6>Alula/Breakthrough M2 Team</h6>
              </div> */}
                </div>
              </>
            ) : (
              <div
                className="card-body text-body"
                dangerouslySetInnerHTML={{
                  __html: dailyNotesData?.nextTasks,
                }}></div>
            )}{" "}
          </div>
          {dailyNotes?.bonusSteps?.length > 0 && (
            <div className="card mt-3">
              <h6
                className="card-header pb-3 fw-semibold"
                style={{
                  fontSize: 20,
                  fontWeight: "700",
                  textAlign: "center",
                  color: "#088eac",
                }}>
                Bonus Sustainability Action Steps:
              </h6>
              <div className="card-body text-body">
                <ol className="list-unstyled">
                  {dailyNotes?.bonusSteps?.map((step, i) => (
                    <li>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          marginBottom: -25,
                        }}>
                        <input
                          onClick={() => handleComplete(i, "bonusSteps")}
                          checked={checkingChecked(i, "bonusSteps")}
                          style={{
                            marginRight: 10,
                            cursor: "pointer",
                            borderRadius: "50%",
                            border: "1px solid #ccc",
                          }}
                          className="form-check-input form-check-input-primary col-md-1"
                          type="checkbox"
                        />
                      </div>
                      <p
                        style={{
                          marginTop: 3,
                          paddingRight: 10,
                          paddingLeft: 35,
                          fontSize: 15,
                          fontWeight: 600,
                          color: "#088eac",
                        }}
                        className="col-md-11"
                        dangerouslySetInnerHTML={{
                          __html: step,
                        }}></p>{" "}
                    </li>
                  ))}
                </ol>
              </div>

              {dailyNotes?.bonusSteps?.length > 0 &&
                dailyNotes.bonusSteps[0] ==
                  "Subscribe to our social media profile" && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      flexDirection: "row",
                      justifyContent: "center",
                      paddingBottom: 20,
                    }}>
                    <a
                      href="https://www.facebook.com/BreakthroughM2Weightloss/"
                      target="_blank"
                      style={{ marginTop: 10 }}>
                      <i className="tf-icons bx bxl-facebook home-icons"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/breakthroughm2/"
                      target="_blank"
                      className="home-icons">
                      <svg
                        style={{ fontSize: 60 }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8A1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5a5 5 0 0 1-5 5a5 5 0 0 1-5-5a5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3Z"
                        />
                      </svg>
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCewcMbF3vZ_VYsP_26u91VQ"
                      target="_blank"
                      className="home-icons">
                      <svg
                        style={{ fontSize: 60, marginLeft: 10 }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="m10 15l5.19-3L10 9v6m11.56-7.83c.13.47.22 1.1.28 1.9c.07.8.1 1.49.1 2.09L22 12c0 2.19-.16 3.8-.44 4.83c-.25.9-.83 1.48-1.73 1.73c-.47.13-1.33.22-2.65.28c-1.3.07-2.49.1-3.59.1L12 19c-4.19 0-6.8-.16-7.83-.44c-.9-.25-1.48-.83-1.73-1.73c-.13-.47-.22-1.1-.28-1.9c-.07-.8-.1-1.49-.1-2.09L2 12c0-2.19.16-3.8.44-4.83c.25-.9.83-1.48 1.73-1.73c.47-.13 1.33-.22 2.65-.28c1.3-.07 2.49-.1 3.59-.1L12 5c4.19 0 6.8.16 7.83.44c.9.25 1.48.83 1.73 1.73Z"
                        />
                      </svg>
                    </a>
                    <a
                      href="https://www.tiktok.com/@breakthroughm2weightloss"
                      target="_blank"
                      className="home-icons">
                      <svg
                        style={{ fontSize: 40, marginLeft: 10 }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 20 20">
                        <path
                          fill="currentColor"
                          fill-rule="evenodd"
                          d="M16 1H4a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zm-3.205 10.519c-.185.382-.373.402-.291 0C12.715 10.48 12.572 8.248 11 8v4.75c0 .973-.448 1.82-1.639 2.203c-1.156.369-2.449-.016-2.752-.846c-.303-.83.377-1.84 1.518-2.256c.637-.232 1.375-.292 1.873-.101V5h1c0 2.355 4.065 1.839 1.795 6.519z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </a>
                  </div>
                )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ActionSteps;
