import React, { useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import {
  getAdminMembers,
  getCoachMembers,
  saveUserPlaneStartsOn,
} from "../store/actions/dashboardAction";
import { getLocalData } from "../utils/functions";
import DateInput from "./DateInput";

const FormDatePicker = ({
  planStartedOn,
  id,
  lowCalorieDate,
  transitionDate,
  planStartedOnForm,
  lowCalorieDateForm,
  transitionDateForm,
  step2LowCalDate,
  step2LowCalDateForm,
  step4TransitionDate,
  step4TransitionDateForm,
  setPage,
  getUserInfo,
  member,
  setLoading,
  loading,
}) => {
  const [date, setDate] = useState(
    moment(
      planStartedOn
        ? planStartedOn
        : lowCalorieDate
        ? lowCalorieDate
        : transitionDate
        ? transitionDate
        : step2LowCalDate
        ? step2LowCalDate
        : step4TransitionDate
        ? step4TransitionDate
        : new Date(),
    )?.format("MM/DD/YYYY"),
  );

  const dispatch = useDispatch();

  const userType = getLocalData("userType");

  const dateChangeHanlder = async () => {
    const payload = {
      id,
    };

    if (planStartedOnForm) {
      payload["planStartedOn"] = new Date(date)?.getTime();
    }
    if (lowCalorieDateForm) {
      payload["lowCalorieDate"] = new Date(date)?.getTime();
    }
    if (transitionDateForm) {
      payload["transitionDate"] = new Date(date)?.getTime();
    }
    if (step2LowCalDateForm) {
      payload["step2LowCalDate"] = new Date(date)?.getTime();
    }
    if (step4TransitionDateForm) {
      payload["step4TransitionDate"] = new Date(date)?.getTime();
    }

    setLoading(true);
    await dispatch(saveUserPlaneStartsOn(payload));
    if (getUserInfo) await getUserInfo(member, true);
    setLoading(false);
    setPage("success");
    setDate(moment(date)?.format("MM/DD/YYYY"));
    if (userType === "admin") {
      dispatch(getAdminMembers());
    } else {
      dispatch(getCoachMembers());
    }
  };

  return (
    <>
      <div style={{ width: "90%" }}>
        <DateInput
          date={date}
          setDate={setDate}
        />
      </div>
      <button
        style={{ width: "90%" }}
        className="btn btn-lg br-10 mt-4"
        onClick={dateChangeHanlder}
        disabled={!date || loading}>
        {loading ? "Saving" : "Save"}
      </button>
    </>
  );
};

export default FormDatePicker;
