import React from "react";

const PhotoStamps = () => {
  const links = [
    {
      label: "Start Photo - Take during your Prep Week or High Fat Days",
      link: "https://alulawellness.com/wp-content/uploads/2023/12/Week-0_Prep-Week.pdf",
    },
    {
      label: "Use this Photo Stamp after Week 1 (Step 2 Low Cal)",
      link: "https://alulawellness.com/wp-content/uploads/2023/12/Week-1_Step-2.pdf",
    },
    {
      label: "Use this Photo Stamp after Week 2 (Step 2 Low Cal)",
      link: "https://alulawellness.com/wp-content/uploads/2023/12/Week-2_Step-2.pdf",
    },
    {
      label: "Use this Photo Stamp after Week 3 (Step 2 Low Cal)",
      link: "https://alulawellness.com/wp-content/uploads/2023/12/Week-3_Step-2.pdf",
    },
    {
      label: "Use this Photo Stamp after Week 4 (Step 2 Low Cal)",
      link: "https://alulawellness.com/wp-content/uploads/2023/12/Week-4_Step-2.pdf",
    },
    {
      label: "Use this Photo Stamp after Week 5 (Step 2 Low Cal)",
      link: "https://alulawellness.com/wp-content/uploads/2023/12/Week-5_Step-2.pdf",
    },
    {
      label: "Use this Photo Stamp after Week 6 (Step 2 Low Cal)",
      link: "https://alulawellness.com/wp-content/uploads/2023/12/Week-6_Step-2.pdf",
    },
    {
      label: "Use this Photo Stamp after Week 7 (Step 2 Low Cal)",
      link: "https://alulawellness.com/wp-content/uploads/2023/12/Week-7_Step-2.pdf",
    },
    {
      label: "Use this Photo Stamp after Week 8 (Step 2 Low Cal)",
      link: "https://alulawellness.com/wp-content/uploads/2023/12/Week-8_Step-2.pdf",
    },
    {
      label: "Use this Photo Stamp after Week 9 (Step 2 Low Cal)",
      link: "https://alulawellness.com/wp-content/uploads/2023/12/Week-9_Step-2.pdf",
    },
    {
      label: "Use this Photo Stamp after Week 10 (Step 2 Low Cal)",
      link: "https://alulawellness.com/wp-content/uploads/2023/12/Week-10_Step-2.pdf",
    },
    {
      label: "Use this Photo Stamp after Week 11 (Step 2 Low Cal)",
      link: "https://alulawellness.com/wp-content/uploads/2023/12/Week-11_Step-2.pdf",
    },
    {
      label: "Use this Photo Stamp after Week 12 (Step 2 Low Cal)",
      link: "https://alulawellness.com/wp-content/uploads/2023/12/Week-12_Step-2.pdf",
    },
    {
      label: "Write-in Blank Low Cal Photo Stamp",
      link: "https://alulawellness.com/wp-content/uploads/2023/12/Week-___Step-2.pdf",
    },
    {
      label: "Write-in End of Step 2 Low Cal Total Pounds Lost!",
      link: "https://alulawellness.com/wp-content/uploads/2023/12/Step-2_Fat-Loss.pdf",
    },
    {
      label: "Use this Photo Stamp after Week 1 (Step 4 Transition)",
      link: "https://alulawellness.com/wp-content/uploads/2023/12/Week-1_Step-4.pdf",
    },
    {
      label: "Use this Photo Stamp after Week 2 (Step 4 Transition)",
      link: "https://alulawellness.com/wp-content/uploads/2023/12/Week-2_Step-4.pdf",
    },
    {
      label: "Use this Photo Stamp after Week 3 (Step 4 Transition)",
      link: "https://alulawellness.com/wp-content/uploads/2023/12/Week-3_Step-4.pdf",
    },
    {
      label: "Use this Photo Stamp after Week 4 (Step 4 Transition)",
      link: "https://alulawellness.com/wp-content/uploads/2023/12/Week-4_Step-4.pdf"
    },
    {
      label: "Use this Photo Stamp after Week 5 (Step 4 Transition)",
      link: "https://alulawellness.com/wp-content/uploads/2023/12/Week-5_Step-4.pdf",
    },
    {
      label: "Use this Photo Stamp after Week 6 (Step 4 Transition)",
      link: "https://alulawellness.com/wp-content/uploads/2023/12/Week-6_Step-4.pdf",
    },
    {
      label: "Completed All Steps: Write-in Total Pounds Lost!",
      link: "https://alulawellness.com/wp-content/uploads/2023/12/Im-Finished.pdf",
    },
  ];
  return (
    <div className="container-xxl flex-grow-1 container-p-b">
      <div className="card shadow-none main-card">
        <div
          className="card-body"
          style={{ minHeight: "100vh" }}>
          {/* <h5
            style={{
              marginBottom: 0,
              fontWeight: 900,
              fontSize: 18,
              color: "#566a7f",
            }}>
            Photo Stamps
          </h5> */}
          <div style={{ paddingTop: 20 }}>
            {links?.map((link) => (
              <div className="posts-block">
                <a
                  style={{ display: "block", paddingTop: 5, fontWeight:'bold' }}
                  target="_blank"
                  href={link.link}>
                  {link.label}
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhotoStamps;
