import React, { useState } from "react";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    boxShadow: "0 2px 20px 0 rgba(67, 89, 113, 0.45)",
  },
  overlay: {
    zIndex: 100000,
  },
};

const ExitModal = ({
  modalIsOpen,
  afterOpenModal,
  closeModal,
  setBtnClicked,
  setShowCofirm,
  onClickHandler
}) => {
  const [loading, setLoading] = useState(false);
  const onClickAction = async () => {
    setLoading(true)
    await onClickHandler();
    setLoading(false)
      closeModal();
  };

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}>
          <p></p>
          <a
            style={{
              fontWeight: 500,
              fontSize: 18,
              color: "#282828",
              zIndex: 10000,
            }}
            role="button"
            onClick={closeModal}>
            X
          </a>
        </div>
        <div
          className="text-center responsive-modal"
          style={{ padding: 10, paddingBottom: 30 }}>
          {/* <h4 className="card-header pb-3 fw-semibold">
            You've decided to end low-cal, now you have two choices.
          </h4> */}
          <div>
            <p
              className="tag-val"
              style={{ fontSize: 20, marginTop: 10, marginBottom: -5 }}>
              <b>STEP 4 - TRANSITION</b>
            </p>
            <p
              style={{ fontSize: 16, textAlign: "left", lineHeight: 1.6 }}
              className="responsive-card-body text-body">
              This is the proper way to exit the Breakthrough M2 program. 
              <br />
              Skipping this step is NOT optional and in doing so, will have a negative impact on your weight loss.
              <br />
              Read pages 50-60 in your BTM2 Manual, conveniently located on your Dashboard.
              <br />
              You have come so far, you have worked so hard, complete what you started and FINISH STRONG!
              <br />
            </p>

            <div className="row d-flex justify-content-between">
              
              <div className="col-md-4">
                <button
                  style={{ width: "90%" }}
                  type="button"
                  onClick={closeModal}
                  className="btn-admin btn-outline-danger responsive-btn full-width">
                  CANCEL
                </button>
              </div>
              <div className="col-md-4">
                <button
                  disabled={loading}
                  style={{ width: "90%" }}
                  type="button"
                  onClick={onClickAction}
                  className="btn-admin btn-outline-confirm responsive-btn full-width">
                  {!loading ? 'MOVE TO STEP 4' : 'Saving..'}  
                </button>
              </div>
              {/* <h6 className="pb-3 fw-semibold mt-4">
                <a
                  role="button"
                  onClick={() => {
                    setBtnClicked(1);
                    setShowCofirm(true);
                    closeModal();
                  }}
                  style={{ color: "#088eac" }}>
                  Click here{" "}
                </a>
                to cancel transition and go back to step 2.
              </h6> */}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ExitModal;
