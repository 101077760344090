import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdminMembers,
  getCoachMembers,
  getMemberInfo,
  getSignups,
} from "../../../store/actions/dashboardAction";
import MemberProfile from "./member-profile";
import { getLocalData } from "../../../utils/functions";
import defaultPic from "../../../assets/img/profile-default.png";
import moment from "moment";
import { usePagination } from "../../../apis/hooks";

const CoachDashboard = ({ coaches, coachId, setLocalData }) => {
  const [pageType, setPageType] = useState("listing");
  const [userInfo, setUserInfo] = useState(null);
  const [userLocal, setUserLocal] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [coachIdS, setCoachIdS] = useState(null);
  const [failed, setFailed] = useState(false);

  const dispatch = useDispatch();
  const [showData, setShowData] = useState([]);

  const { coachMebers } = useSelector((state) => state.dashboard);
  const { isLoading } = useSelector((state) => state.ui);
  const { loginUser } = useSelector((state) => state.auth);

  const userType = getLocalData("userType");

  const pagination = usePagination(
    coaches
      ? coachMebers
          ?.filter((member) => member.isCoach)
          ?.filter((member) =>
            searchText
              ? member?.name
                  ?.toLowerCase()
                  ?.includes(searchText?.toLowerCase()) ||
                member?.email
                  ?.toLowerCase()
                  ?.includes(searchText?.toLowerCase()) ||
                String(member?.id)
                  ?.toLowerCase()
                  ?.includes(searchText?.toLowerCase())
              : member,
          )?.length
      : coachMebers
          ?.filter((member) => !member.isCoach)
          ?.filter((member) =>
            searchText
              ? member?.name
                  ?.toLowerCase()
                  ?.includes(searchText?.toLowerCase()) ||
                member?.email
                  ?.toLowerCase()
                  ?.includes(searchText?.toLowerCase())
              : member,
          )
          ?.filter((member) =>
            coachIdS
              ? coachIdS == 0
                ? !member?.assignedCoachId
                : coachIdS == member?.assignedCoachId
              : member,
          )
          ?.filter((member) =>
            coachId ? coachId == member?.assignedCoachId : member,
          )?.length,
    20,
  );

  useEffect(() => {
    if (coachMebers?.length && pagination.current > 0) {
      if (coaches) {
        setShowData(
          coachMebers
            ?.filter((member) => member.isCoach)
            ?.slice((pagination.current - 1) * 20, pagination.current * 20),
        );
      } else {
        setShowData(
          coachMebers
            ?.filter((member) => !member.isCoach)
            ?.filter((member) =>
              coachIdS
                ? coachIdS == 0
                  ? !member?.assignedCoachId
                  : member?.assignedCoachId &&
                    member?.assignedCoachId == coachIdS
                : member,
            )
            ?.filter((member) =>
              coachId
                ? member?.assignedCoachId && member?.assignedCoachId == coachId
                : member,
            )
            ?.filter((member) =>
              searchText
                ? member?.name
                    ?.toLowerCase()
                    ?.includes(searchText?.toLowerCase()) ||
                  member?.email
                    ?.toLowerCase()
                    ?.includes(searchText?.toLowerCase()) ||
                  String(member?.id)
                    ?.toLowerCase()
                    ?.includes(searchText?.toLowerCase())
                : coachIdS
                ? coachIdS == 0
                  ? !member?.assignedCoachId
                  : member?.assignedCoachId &&
                    member?.assignedCoachId == coachIdS
                : member,
            )
            ?.slice((pagination.current - 1) * 20, pagination.current * 20),
        );
      }
    }
  }, [coachMebers, pagination.current, coachIdS, searchText, coachId]);

  useEffect(() => {
    if (userType === "admin") {
      dispatch(getAdminMembers());
    } else if (userType === "coach") {
      dispatch(getCoachMembers());
    }
  }, []);

  const addDefaultSrc = (ev) => {
    ev.target.src =
      "https://dashboard.alulawellness.com/breakthrough-uploads/user-default.png";
  };

  const getUserInfo = async (user, modal) => {
    if (coaches) {
      setUserLocal({ ...user });
      setPageType("profile");
    } else {
      if (!modal) setUserInfo(null);
      setFailed(false);
      setUserLocal({ ...user });
      setPageType("profile");
      const res = await dispatch(getMemberInfo(user?.id));
      if (res?.email) {
        setUserInfo(res);
      } else {
        setFailed(true);
      }
    }
  };

  const getCoachName = (id) => {
    return coachMebers?.find((user) => user?.id === id)?.name || "";
  };

  return (
    <>
      {pageType === "listing" ? (
        <div className="container-xxl flex-grow-1 container-p-b">
          <div className="card shadow-none main-card">
            <div className="card-body">
              <div className="row g-3 flex-reverse-mob">
                <div className="col-md-12">
                  <div className="card h-100">
                    <div className="card-header pb-3">
                      {!coachId && (
                        <h5 className="fw-semibold">
                          All {coaches ? "Coaches" : "Members"} (
                          {coaches
                            ? coachMebers
                                ?.filter((member) => member.isCoach)
                                ?.filter((member) =>
                                  searchText
                                    ? member?.name
                                        ?.toLowerCase()
                                        ?.includes(searchText?.toLowerCase()) ||
                                      member?.email
                                        ?.toLowerCase()
                                        ?.includes(searchText?.toLowerCase()) ||
                                      String(member?.id)
                                        ?.toLowerCase()
                                        ?.includes(searchText?.toLowerCase())
                                    : member,
                                )?.length
                            : coachMebers
                                ?.filter((member) => !member.isCoach)
                                ?.filter((member) =>
                                  searchText
                                    ? member?.name
                                        ?.toLowerCase()
                                        ?.includes(searchText?.toLowerCase()) ||
                                      member?.email
                                        ?.toLowerCase()
                                        ?.includes(searchText?.toLowerCase()) ||
                                      String(member?.id)
                                        ?.toLowerCase()
                                        ?.includes(searchText?.toLowerCase())
                                    : member,
                                )
                                ?.filter((member) =>
                                  coachIdS
                                    ? coachIdS == 0
                                      ? !member?.assignedCoachId
                                      : coachIdS == member?.assignedCoachId
                                    : member,
                                )
                                ?.filter((member) =>
                                  coachId
                                    ? coachId == member?.assignedCoachId
                                    : member,
                                )?.length}
                          )
                        </h5>
                      )}

                      {!coaches && !coachId && (
                        <div className="row">
                          <div className="col-md-3">
                            <div className="input-group input-group-merge">
                              <span
                                className="input-group-text"
                                id="basic-addon-search31">
                                <i className="bx bx-search"></i>
                              </span>
                              <input
                                type="text"
                                value={searchText}
                                className="form-control form-control-sm bg-white"
                                placeholder="Search members..."
                                aria-label="Search..."
                                aria-describedby="basic-addon-search31"
                                onChange={(e) => {
                                  setSearchText(e.target.value);
                                  pagination.goToPage(1);
                                }}
                              />
                            </div>
                          </div>
                          <div className="form-group col-md-2 -me-2 me-xl-3">
                            <select
                              className="form-select form-control form-control-sm border bg-white"
                              onChange={(e) => {
                                setCoachIdS(e.target.value);
                                pagination.goToPage(1);
                              }}
                              style={{ color: "#333" }}>
                              <option
                                value=""
                                selected>
                                All Coaches
                              </option>
                              <option value="0">Group Coaching</option>
                              {coachMebers
                                ?.filter((member) => member?.isCoach)
                                ?.map((member) => (
                                  <option
                                    selected={coachIdS == member?.id}
                                    value={member?.id}>
                                    {member.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      )}

                      {/* <p className="text-body">
                    Take a look back and see what you’ve been doing
                  </p> */}

                      {coachId && (
                        <div className="col-md-12 text-md-start back-btn-box">
                          <a
                            onClick={() => setLocalData(null)}
                            className="back-btn">
                            &lsaquo; Back To Listing
                          </a>

                          <h5
                            className="fw-semibold mt-4"
                            style={{ marginBottom: -20 }}>
                            Assigned Members{" "}
                          </h5>
                        </div>
                      )}
                    </div>
                    <div className="table-responsive-xs text-nowrap mb-4">
                      <table className="table table-borderless medium data-table-block">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Name</th>
                            {!coaches && <th>Email</th>}
                            {!coaches && <th>Start Date</th>}
                            <th>Last Login</th>

                            {!coaches && <th>Coach Name</th>}
                            {coaches && <th>Total Members</th>}
                            {!coachId && <th></th>}
                          </tr>
                        </thead>
                        <tbody className="table-border-bottom-0 text-1e">
                          {showData?.map((member) => (
                            <tr
                              key={member?.id}
                              className="text-1e">
                              <td>{member?.id}</td>
                              <td>
                                <img
                                  style={{ marginBottom: 10, objectFit:'cover' }}
                                  onError={addDefaultSrc}
                                  src={
                                    member?.profilePic
                                      ? member?.profilePic
                                      : defaultPic
                                  }
                                  alt="Avatar"
                                  className="w-px-40 h-px-40 rounded-circle me-2"
                                />
                                {member?.name}
                              </td>
                              {!coaches && <td>{member?.email}</td>}
                              {!coaches && (
                                <td>
                                  {member?.planStartedOn
                                    ? moment(member?.planStartedOn)?.format(
                                        "MM/DD/YYYY",
                                      )
                                    : "-"}
                                </td>
                              )}
                              <td>
                                {member?.lastLogin
                                  ? moment(member?.lastLogin)?.format(
                                      "MM/DD/YYYY",
                                    )
                                  : "-"}
                              </td>
                              {!coaches && (
                                <td>
                                  {member?.assignedCoachId
                                    ? getCoachName(member?.assignedCoachId)
                                    : "-"}
                                </td>
                              )}
                              {coaches && (
                                <td>
                                  {
                                    coachMebers?.filter(
                                      (val) =>
                                        val?.assignedCoachId == member?.id,
                                    )?.length
                                  }
                                </td>
                              )}
                              {!coachId && (
                                <td>
                                  <button
                                    onClick={() => getUserInfo(member)}
                                    className="btn br-10"
                                    style={{
                                      marginTop:
                                        userType === "admin"
                                          ? coaches
                                            ? 15
                                            : 25
                                          : 15,
                                    }}>
                                    {coaches ? "View Members" : "View Profile"}
                                  </button>
                                </td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div id="state-scroll">
                      {isLoading && !coachMebers?.length && (
                        <div className="loading-text">
                          <p>Loading...</p>
                        </div>
                      )}
                      {!isLoading &&
                        !coachMebers
                          ?.filter((member) =>
                            coaches
                              ? member?.isCoach
                              : coachId
                              ? member?.assignedCoachId == coachId
                              : !member?.isCoach,
                          )
                          ?.filter((member) =>
                            searchText
                              ? member?.name
                                  ?.toLowerCase()
                                  ?.includes(searchText?.toLowerCase()) ||
                                member?.email
                                  ?.toLowerCase()
                                  ?.includes(searchText?.toLowerCase()) ||
                                String(member?.id)
                                  ?.toLowerCase()
                                  ?.includes(searchText?.toLowerCase())
                              : coachIdS
                              ? coachIdS == 0
                                ? !member?.assignedCoachId
                                : member?.assignedCoachId == coachIdS
                              : member,
                          )?.length && (
                          <div className="loading-text">
                            <p>No Data</p>
                          </div>
                        )}
                    </div>
                    {pagination?.totalPages > 1 && !isLoading && (
                      <nav aria-label="Page navigation">
                        <ul className="pagination justify-content-end mt-5">
                          <li
                            className="page-item prev"
                            disabled={pagination.current === 1}>
                            <a
                              role="button"
                              onClick={pagination.onPrevClick}
                              className="page-link">
                              <i className="tf-icon bx bx-chevron-left"></i>
                            </a>
                          </li>

                          {pagination.links.map((item, idx) => {
                            if (item === "...") {
                              return (
                                <li className="page-item">
                                  <p
                                    style={{ marginLeft: 0 }}
                                    className="page-link">
                                    ...
                                  </p>
                                </li>
                              );
                            } else {
                              return (
                                <li
                                  className={`page-item ${
                                    pagination.current === item ? "active" : ""
                                  }`}>
                                  <a
                                    className="page-link"
                                    role="button"
                                    onClick={() =>
                                      pagination.current !== item &&
                                      pagination.goToPage(item)
                                    }>
                                    {item}
                                  </a>
                                </li>
                              );
                            }
                          })}

                          <li
                            className="page-item next"
                            disabled={
                              pagination.current === pagination.totalPages
                            }>
                            <a
                              role="button"
                              onClick={pagination.onNextClick}
                              className="page-link">
                              <i className="tf-icon bx bx-chevron-right"></i>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <MemberProfile
          userInfo={userInfo}
          setPageType={setPageType}
          userLocal={userLocal}
          coaches={coaches}
          getUserInfo={getUserInfo}
          setLocalData={setUserLocal}
          failed={failed}
        />
      )}
    </>
  );
};

export default CoachDashboard;
