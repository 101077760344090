import React, { useEffect } from "react";
import { setUserData } from "../store/actions/authAction";
import { setDashboardData } from "../store/actions/dashboardAction";
import { useDispatch } from "react-redux";

const PublicLayout = ({ children, history }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.location?.search?.includes("?auth_refresh")) {
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      localStorage.removeItem("mo_jwt_token");
      dispatch(setUserData({ token: null, loginUser: null }));
      dispatch(setDashboardData({ userWeights: null, notifications: null }));
      history.push("/login");
    }
  }, [window.location?.search]);

  return <>{children}</>;
};

export default PublicLayout;
