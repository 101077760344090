import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ApexCharts from "apexcharts";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import CheatedModal from "../../modals/cheated-modal";
import {
  getDailyNotes,
  getReferralData,
  getUserStats,
  getWeights,
  updateUserData,
  updateUserStats,
  getNotificationStatus,
  getNotifications,
  getResetDataAction,
} from "../../store/actions/dashboardAction";
import ReferFriend from "../../modals/refer-friend-modal";
import TestimonialModal from "../../modals/testimonial-modal";
import CoachCalendly from "../../modals/coach-calendly";
import { getUserProfile } from "../../store/actions/authAction";
import FormTextField from "../../forms/FormTextField";
import HabitTracker from "../../components/habit-tracker";
import ActionSteps from "../../components/action-steps";
import moment from "moment";
import VideoTutorial from "../../modals/video-tutorial";
import ValidationModal from "../../modals/validation-modal";

const updateSchema = yup.object({
  targetedWeight: yup
    .string()
    .trim()
    .required("Cannot be empty")
    .matches(/^[0-9]\d{0,9}(\.\d{1,5})?%?$/, "Invalid Value"),
});

const Home = ({ loginUser, history }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [validationModal, setValidationModal] = useState(false);
  const [referFriend, setReferFriend] = useState(false);
  const [modalType, setModalType] = useState("");
  const [success, setSuccess] = useState("");
  const [successTodays, setSuccessTodays] = useState("");
  const [testimonial, setTestimonial] = useState(false);
  const [coachCalendly, setCoachCalendly] = useState(false);
  const [loadingChart, setLoadingChart] = useState(false);
  const [loading, setLoading] = useState(false);
  const [videoTutorial, setVideoTutorial] = useState(true);
  const [loadingToday, setLoadingToday] = useState(false);
  const [todayWeight, setTodayWeight] = useState("");

  const dispatch = useDispatch();

  const {
    userStats,
    userWeights,
    referralData,
    dailyNotes: dailyNotesData,
  } = useSelector((state) => state.dashboard);

  const {
    register: registerUpdate,
    handleSubmit: handleSubmitUpdate,
    setValue,
    reset: resetUpdate,
    formState: { errors: errorsUpdate },
  } = useForm({
    resolver: yupResolver(updateSchema),
  });

  const config = {
    colors: {
      primary: "#6f8f40",
      secondary: "#55C9E3",
      success: "#71dd37",
      info: "#03c3ec",
      warning: "#ffab00",
      danger: "#D95B4C",
      dark: "#233446",
      black: "#000",
      white: "#fff",
      body: "#f4f5fb",
      headingColor: "#566a7f",
      axisColor: "#6D778E",
      borderColor: "#eceef1",
    },
  };

  useEffect(() => {
    if (loginUser?.isClient) {
      getResetData();
    }
  }, [loginUser?.id]);

  const getResetData = async () => {
    const targetDate = moment("2024-10-01");
    const res = await dispatch(getResetDataAction());

    console.log('medical form and info form', loginUser?.medicalForm, loginUser?.infoForm)
    if (
      !res.isPresent &&
      moment(loginUser?.createdAt).isSameOrAfter(targetDate)
      && (!loginUser?.medicalForm || !loginUser?.infoForm)
    ) {
      setValidationModal(true);
      console.log(
        "The timestamp is greater than or equal to October 1, 2024.",
        loginUser?.createdAt,
      );
    }

    // if(!res?.isPresent)
    console.log("res>>>>", res);
    console.log("res>>>>", loginUser);
  };

  useEffect(() => {
    console.log(" -- - -loginUser", loginUser);

    if(loginUser){
    const redirectUrl = sessionStorage.getItem("redirectUrl");

        if (redirectUrl) {
          history.push(redirectUrl);
          sessionStorage.removeItem("redirectUrl");
          return;
        }
      }

    if (loginUser?.startWeight && loginUser?.goalWeight) {
      setLoadingChart(true);
      setupLbsChart();
    }

    // setVideoTutorial(true);
    setVideoTutorial(loginUser?.newUser);
  }, [loginUser?.startWeight]);

  useEffect(() => {
    if (userWeights?.length > 0) {
      setTimeout(() => {
        setupWeightGraph(userWeights);
      }, 100);
    }
  }, [userWeights?.length]);

  useEffect(() => {
    dispatch(getUserStats());
    dispatch(getReferralData());

    if (loginUser?.isClient) {
      dispatch(getNotifications());
      dispatch(getNotificationStatus());
    }
  }, []);

  useEffect(() => {
    if (userStats?.length) {
      const todaysData = userStats.find(
        (val) =>
          moment(new Date()).format("MM/DD/YYYY") ===
          moment(val?.date).format("MM/DD/YYYY"),
      );
      if (todaysData?.weight) {
        setTodayWeight(Number(todaysData?.weight).toFixed(2));
      }
    }
  }, [userStats]);

  const setupLbsChart = () => {
    let weightLossPercent = 100;

    if (loginUser?.startWeight - loginUser?.goalWeight > 0) {
      weightLossPercent =
        ((loginUser?.startWeight - loginUser?.currentWeight) * 100) /
        (loginUser?.startWeight - loginUser?.goalWeight);
    }

    const lbsConfig = {
      series: [weightLossPercent ? weightLossPercent : 1],
      chart: {
        width: 125,
        height: 125,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          strokeWidth: "10px",
          hollow: {
            margin: 2,
            size: "50%",
          },
          track: {
            strokeWidth: "100%",
            background: "rgba(0,0,0,.2)",
          },
          dataLabels: {
            show: true,
            name: {
              show: false,
            },
            value: {
              formatter: function (val) {
                let lost = loginUser?.startWeight - loginUser?.currentWeight;
                lost = lost > 0 ? lost : 0;
                lost = lost.toFixed(1);
                return `${lost} lbs`;
              },
              offsetY: -8,
              color: "#FFFFFF",
              fontSize: "16px",
              show: true,
              fontWeight: "600",
            },
          },
        },
      },
      fill: {
        type: "solid",
        colors: "#FFFFFF",
      },
      stroke: {
        lineCap: "round",
      },
      grid: {
        padding: {
          top: -20,
          bottom: -25,
          left: -30,
          right: -30,
        },
      },
      states: {
        hover: {
          filter: {
            type: "none",
          },
        },
        active: {
          filter: {
            type: "none",
          },
        },
      },
    };

    var lbsChartOrigin = document.querySelector("#lbs");

    if (lbsChartOrigin) {
      const lbsChart = new ApexCharts(
        document.querySelector("#lbs"),
        lbsConfig,
      );
      lbsChart.render();
    }
  };

  const setupWeightGraph = (weights) => {
    const weightGraphConfig = {
      series: [
        {
          name: "Weight",
          data: weights?.map((val) => val.weight),
        },
        // {
        //   name: "Weight Gain",
        //   data: [180, 167, 195, 167, 195, 162, 182, 175],
        // },
      ],
      chart: {
        height: 215,
        parentHeightOffset: 0,
        parentWidthOffset: 0,
        toolbar: {
          show: false,
        },
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 1,
        curve: "smooth",
      },
      legend: {
        show: false,
      },
      markers: {
        size: 4,
        // colors: [config.colors.primary, config.colors.danger],
        colors: [config.colors.primary],
        strokeColors: "",
        strokeWidth: 2,
        // discrete: [
        //   {
        //     fillColor: config.colors.white,
        //     seriesIndex: 0,
        //     dataPointIndex: 7,
        //     strokeColor: config.colors.primary,
        //     strokeWidth: 2,
        //     size: 6,
        //     radius: 8,
        //   },
        // ],
        hover: {
          size: 7,
        },
      },
      colors: ["#6f8f40", config.colors.white],
      fill: {
        type: "gradient",
      },
      grid: {
        borderColor: config.colors.borderColor,
        strokeDashArray: 3,
        padding: {
          top: 0,
          bottom: 0,
          left: -0,
          right: 0,
        },
      },

      legend: {
        position: "top",
        horizontalAlign: "right",
      },
      xaxis: {
        categories: weights?.map((val) => `Day ${val?.day}`),
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
          style: {
            fontSize: "10px",
            colors: config.colors.axisColor,
          },
        },
      },
      yaxis: {
        labels: {
          offsetX: -15,
          offsetY: 0,
          style: {
            fontSize: "10px",
            colors: config.colors.axisColor,
          },
          formatter: function (value) {
            return value;
          },
        },
        min: 100,
        max: 400,
        tickAmount: 3,
      },
    };

    var weightChartOrigin = document.querySelector("#weight");

    if (weightChartOrigin) {
      var weightGraph = new ApexCharts(
        document.querySelector("#weight"),
        weightGraphConfig,
      );
      weightGraph.render();
    }
  };

  useEffect(() => {
    if (loginUser?.contentDay) dispatch(getDailyNotes(loginUser?.contentDay));
  }, [loginUser?.contentDay]);

  const openCoachCalendly = () => {
    setIsOpen(false);
    setCoachCalendly(true);
  };

  const updateData = async (data) => {
    const payload = {
      goal_weight: Number(data?.targetedWeight).toFixed(2),
    };

    setLoading(true);
    const res = await dispatch(updateUserData(payload));
    setLoading(false);

    if (res.status === "success") {
      history.push("/");
    }
  };

  useEffect(() => {
    if (loginUser?.goalWeight)
      setValue("targetedWeight", loginUser?.goalWeight);
  }, [loginUser]);

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const todayDataSave = async () => {
    const selectedData = userStats.find(
      (val) =>
        moment(new Date()).format("MM/DD/YYYY") ===
        moment(val?.date).format("MM/DD/YYYY"),
    );

    if (selectedData?.id && todayWeight) {
      const payload = {
        id: selectedData?.id,
        weight: todayWeight,
        feeling: selectedData?.feeling,
        date: selectedData?.date,
        chest: Number(selectedData?.chest) ? Number(selectedData?.chest) : 0,
        waist: Number(selectedData?.waist) ? Number(selectedData?.waist) : 0,
        bicep: Number(selectedData?.bicep) ? Number(selectedData?.bicep) : 0,
        thigh: Number(selectedData?.thigh) ? Number(selectedData?.thigh) : 0,
      };
      setLoadingToday(true);
      await dispatch(updateUserStats(payload));
      await dispatch(getUserProfile());
      await dispatch(getWeights());
      setLoadingToday(false);
      history.push("/");
    } else if (todayWeight) {
      sessionStorage.setItem("todaysWeight", todayWeight);
      history.push("/daily-stats");
    }
  };

  return (
    <>
      <div className="container-xxl flex-grow-1 container-p-b">
        <div className="card shadow-none main-card">
          <div
            className="card-body"
            style={{ paddingBottom: 80 }}>
            <div className="row g-3">
              {loginUser &&
                loginUser?.completed !== 1 &&
                loginUser?.isClient && (
                  <div
                    className="col-lg-4 col-md-6 col-sm-6"
                    style={{ position: "relative", paddingBottom: 130 }}>
                    <HabitTracker />
                    {dailyNotesData?.dailyQuote?.quote && (
                      <div className="card mb-3">
                        <div className="card">
                          <div
                            className="card-body"
                            style={{
                              padding: "1rem 0rem",
                              textAlign: "center",
                              color: "#DC5F50",
                            }}>
                            <h4
                              style={{
                                fontSize: 20,
                                fontWeight: 700,
                                marginBottom: 0,
                              }}>
                              Daily Dose of Motivation
                            </h4>

                            <div
                              className="card-body text-body dailyQuote-color"
                              style={{ fontSize: 18 }}
                              dangerouslySetInnerHTML={{
                                __html:
                                  '"' + dailyNotesData?.dailyQuote?.quote + '"',
                              }}></div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="card mb-3">
                      <div className="row">
                        <div
                          className="col-6 card-body"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                          }}>
                          <h5
                            style={{
                              color: "#dc5f50",
                              textAlign: "center",
                              lineHeight: 1.5,
                            }}>
                            <b> Referrals:</b> <br />
                            <span style={{ fontWeight: "600" }}>
                              {referralData
                                ? referralData?.total_referrals
                                : "0"}
                            </span>
                          </h5>
                          <a
                            role="button"
                            style={{ fontSize: 14, color: "#fff" }}
                            onClick={() => {
                              history.push("/referral");
                            }}
                            className="btn btn-4 px-3">
                            Refer Friends
                          </a>
                        </div>
                        <div
                          className="col-6 card-body"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                          }}>
                          <h5
                            style={{
                              color: "#dc5f50",
                              textAlign: "center",
                              lineHeight: 1.5,
                            }}>
                            <b>Credit:</b> <br />
                            <span style={{ fontWeight: "600" }}>
                              $
                              {referralData
                                ? referralData?.credit_amount
                                : "0.00"}
                            </span>
                          </h5>
                          <a
                            role="button"
                            style={{
                              fontSize: 14,
                              color: "#fff",
                            }}
                            onClick={() => {
                              history.push("/referral");
                            }}
                            className="btn btn-4 px-3">
                            Redeem Now!
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="web-hide">
                      <a
                        role="button"
                        style={{
                          bottom: 0,
                          height: 90,
                          fontSize: 16,
                          color: "#fff",
                          width: "100%",
                          pointerEvents:
                            referralData?.affiliate_code_requested?.[0] ===
                              "TRUE" && "none",
                          opacity:
                            referralData?.affiliate_code_requested?.[0] ===
                              "TRUE" && 0.65,
                        }}
                        onClick={() => {
                          setReferFriend(true);
                          setModalType("Get Personal Affiliate Code");
                        }}
                        className="btn btn-4 px-3">
                        <p style={{ marginTop: 10 }}>
                          Get Personal <br /> Affiliate Code
                        </p>
                      </a>

                      {referralData?.affiliate_code && (
                        <span
                          className="mt-3"
                          style={{
                            color: "#333",
                            textAlign: "left",
                            fontWeight: 600,
                          }}>
                          Affiliate Code: {referralData?.affiliate_code}
                        </span>
                      )}
                      {referralData &&
                        referralData?.affiliate_code_requested?.[0] ===
                          "TRUE" &&
                        !referralData?.affiliate_code && (
                          <span
                            className="mt-3"
                            style={{
                              color: "#333",
                              textAlign: "left",
                              fontWeight: 600,
                            }}>
                            Request sent
                          </span>
                        )}
                    </div>

                    {/* <div className="col-12 card-body">
                  <a
                    role="button"
                    style={{
                      fontSize: 10,
                      color: "#fff",
                    }}
                    onClick={scrollToBottom}
                    className="btn btn-5 px-3">
                    Important Notes
                  </a>
                  <div style={{ textAlign: "center" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="3em"
                      fill="#0e4d5f"
                      viewBox="0 0 320 512">
                      <path d="M2 334.5c-3.8 8.8-2 19 4.6 26l136 144c4.5 4.8 10.8 7.5 17.4 7.5s12.9-2.7 17.4-7.5l136-144c6.6-7 8.4-17.2 4.6-26s-12.5-14.5-22-14.5l-72 0 0-288c0-17.7-14.3-32-32-32L128 0C110.3 0 96 14.3 96 32l0 288-72 0c-9.6 0-18.2 5.7-22 14.5z" />
                    </svg>
                  </div>
                </div> */}
                  </div>
                )}

              <div
                className="col-lg-4 col-md-6 col-sm-6"
                style={{ position: "relative" }}>
                  
                {loginUser?.completed !== 1 && loginUser?.isClient && (
                  <>
                    <div className="card mb-3">
                      <div className="card gradient1 text-white">
                        <div className="card-body">
                          <div className="row g-3 align-items-center">
                            <div className="col-12">
                              <img
                                src="assets/img/calendar.svg"
                                className="mb-3"
                                height="40"
                              />
                              <h4 className="fw-bold mb-0">
                                Days on Low Cal : {loginUser?.lowCalDays}
                              </h4>
                              <br />
                              <div style={{ marginTop: 5 }}>
                                Step 1 Start Prep :{" "}
                                {loginUser?.step2LowCalDate
                                  ? moment(new Date(loginUser?.step2LowCalDate))
                                      .subtract(7, "days")
                                      .format("MM/DD/YYYY")
                                  : loginUser?.planStartedOn
                                  ? moment(
                                      new Date(loginUser?.planStartedOn),
                                    ).format("MM/DD/YYYY")
                                  : "-"}
                              </div>
                              <div style={{ marginTop: 5 }}>
                                High Fat Days :{" "}
                                {loginUser?.step2LowCalDate
                                  ? moment(new Date(loginUser?.step2LowCalDate))
                                      .subtract(2, "days")
                                      .format("MM/DD/YYYY") +
                                    ", " +
                                    moment(new Date(loginUser?.step2LowCalDate))
                                      .subtract(1, "days")
                                      .format("MM/DD/YYYY")
                                  : loginUser?.lowCalorieDate
                                  ? moment(new Date(loginUser?.lowCalorieDate))
                                      .subtract(2, "days")
                                      .format("MM/DD/YYYY") +
                                    ", " +
                                    moment(new Date(loginUser?.lowCalorieDate))
                                      .subtract(1, "days")
                                      .format("MM/DD/YYYY")
                                  : "-"}
                              </div>
                              <div style={{ marginTop: 5 }}>
                                Step 2 Start Low Cal :{" "}
                                {loginUser?.step2LowCalDate
                                  ? moment(
                                      new Date(loginUser?.step2LowCalDate),
                                    ).format("MM/DD/YYYY")
                                  : loginUser?.lowCalorieDate
                                  ? moment(
                                      new Date(loginUser?.lowCalorieDate),
                                    ).format("MM/DD/YYYY")
                                  : "-"}
                              </div>
                              <div style={{ marginTop: 5 }}>
                                Step 3 Start Stabilize :{" "}
                                {loginUser?.step4TransitionDate
                                  ? moment(
                                      new Date(loginUser?.step4TransitionDate),
                                    )
                                      .subtract(2, "days")
                                      .format("MM/DD/YYYY")
                                  : loginUser?.transitionDate
                                  ? moment(new Date(loginUser?.transitionDate))
                                      .subtract(2, "days")
                                      .format("MM/DD/YYYY")
                                  : "-"}
                              </div>
                              <div style={{ marginTop: 5 }}>
                                Step 4 Start Transition :{" "}
                                {loginUser?.step4TransitionDate
                                  ? moment(
                                      new Date(loginUser?.step4TransitionDate),
                                    ).format("MM/DD/YYYY")
                                  : loginUser?.transitionDate
                                  ? moment(
                                      new Date(loginUser?.transitionDate),
                                    ).format("MM/DD/YYYY")
                                  : "-"}
                              </div>
                            </div>
                            {/* <div className="col-5">
                                <h3
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "center",
                                  }}>
                                  {loginUser?.completedDays}
                                </h3>
                              </div> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <ActionSteps dailyNotesData={dailyNotesData} />

                    <div className="card mb-3 mt-3">
                      <h6
                        className="card-header pb-3 fw-semibold"
                        style={{
                          fontSize: 20,
                          fontWeight: "bolder",
                          textAlign: "center",
                          color: "#088eac",
                          marginBottom: -10,
                        }}>
                        Share Your Success!
                      </h6>
                      <div className="row">
                        <div className="col-6 card-body tooltip-side">
                          <span className="tooltiptext-success" style={{textAlign:'left', margin:'0 auto'}}>
                          Now that you have met your goal, do you want to scream it from the rooftops!? 
                          <br/>
                          The Breakthrough M2 Success Scream is an opportunity for you to have an interview with Nan about your amazing journey on the Breakthrough program. 
                          <br/>
                          At the end of the online interview via Zoom, you get to scream loud and proud about what you have accomplished! 
                          <br/>
                          This is a time for Breakthrough to celebrate with you and for you to share and inspire others about what is possible in the name of wellness!
                          </span>
                          <a
                            role="button"
                            style={{ fontSize: 14, color: "#fff" }}
                            onClick={() => {
                              setReferFriend(true);
                              setModalType("Schedule Success Scream Interview!");
                            }}
                            className="btn px-3">
                            Schedule Success Scream Interview!
                          </a>
                        </div>
                        <div
                          className="col-6 card-body"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}>
                          <a
                            role="button"
                            style={{
                              fontSize: 13,
                              color: "#fff",
                            }}
                            onClick={() => {
                              setTestimonial(true);
                            }}
                            className="btn px-3">
                            Submit Testimonial 
                            <br />& Before/ Afters
                          </a>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}>
                        <div className="col-6 card-body ">
                          <a
                            role="button"
                            style={{
                              fontSize: 14,
                              color: "#fff",
                              minWidth: 150,
                            }}
                            onClick={() => {
                              window.open("https://bit.ly/3vfsVGL");
                            }}
                            className="btn px-3">
                            Google Review
                          </a>
                        </div>
                        <div
                          className="col-6 card-body"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}>
                          <a
                            role="button"
                            style={{
                              fontSize: 14,
                              color: "#fff",
                            }}
                            onClick={() => {
                              window.open(
                                "https://www.facebook.com/BreakthroughM2Weightloss/reviews",
                              );
                            }}
                            className="btn px-3">
                            Facebook Review
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="web-hide">
                      <div
                        style={{
                          width: "100%",
                          cursor: "pointer",
                          borderRadius: "50px",
                          height: 90,
                        }}
                        className="card gradient1 text-white  mb-3 mt-3"
                        onClick={() => setIsOpen(true)}>
                        <div
                          className="card-body text-center"
                          style={{ padding: "0.8rem 1rem" }}>
                          <h5 className="card-header fw-semibold p-0 text-uppercase text-white">
                            <i className="bx bx-error h5 mb-0"></i> Cheated?
                          </h5>
                          <p className="mb-0 mt-2">
                            Considering cheating? <br />
                            What's it worth?
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div
                className="col-lg-4 col-md-6 col-sm-6 margin-top-mobile-container"
                style={{ position: "relative" }}>
                {loginUser?.completed !== 1 && loginUser?.isClient && (
                  <div
                    className="card mb-3"
                    style={{ height: 150, backgroundColor: "" }}>
                    <div className="card gradient3 text-white">
                      <div
                        className="card-body"
                        style={{ height: 150, backgroundColor: "" }}>
                        <div
                          className="row g-3 align-items-center"
                          style={{ height: 150, backgroundColor: "" }}>
                          <div className="col-7">
                            <img
                              src="assets/img/dumbbell.png"
                              className="mb-3"
                              height="40"
                            />
                            <h4 className="fw-bold mb-0">Total LBS Lost</h4>
                            {/* <span
                            className="d-flex align-items-center justify-content-between text-center fw-bold"
                            style={{ fontSize: 12, marginTop: -20 }}>

                            <span>
                              Start
                              <br />
                              {loginUser?.startWeight} lbs
                            </span>
                            <span>
                              Goal
                              <br />
                              {loginUser?.goalWeight} lbs
                            </span>
                          </span> */}
                          </div>
                          <div
                            className="col-5"
                            style={{ backgroundColor: "", height: 140 }}>
                            <div
                              id="lbs"
                              style={{
                                backgroundColor: "",
                                height: 120,
                              }}></div>
                            {loginUser?.startWeight && loginUser?.goalWeight ? (
                              <div
                                className="d-flex align-items-center justify-content-between fw-bold"
                                style={{
                                  fontSize: 12,
                                  marginTop: -40,
                                  width: 160,
                                }}>
                                <div style={{ width: 160 }}>
                                  Start &nbsp; &nbsp; &nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  Goal
                                  <br />
                                  {Number(loginUser?.startWeight)?.toFixed(
                                    1,
                                  )}{" "}
                                  lbs &nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  {Number(loginUser?.goalWeight)?.toFixed(1)}{" "}
                                  lbs
                                </div>
                                {/* <div>
                              Goal
                              <br />
                              {loginUser?.goalWeight} lbs
                            </div> */}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {loginUser &&
                  loginUser?.completed !== 1 &&
                  loginUser?.isClient && (
                    <div className="card mt-3">
                      <div className="card-body text-body">
                        <h5
                          style={{ color: "#1e2a42", marginBottom: 0 }}
                          className="fw-semibold">
                          Today's Weight (lbs)
                        </h5>
                        <form
                          className="d-flex flex-column h-100"
                          onSubmit={handleSubmitUpdate(updateData)}>
                          <div className="row align-items-end">
                            <div className="col-md-7 mt-3">
                              <input
                                placeholder=""
                                type="text"
                                className="form-control form-control-2"
                                onChange={(e) => setTodayWeight(e.target.value)}
                                value={todayWeight}
                              />
                            </div>

                            <div className="col-md-5 mb-2 margin-top-mobile">
                              <button
                                type="button"
                                disabled={loadingToday}
                                onClick={todayDataSave}
                                className="btn btn-2 br-10 w-100">
                                {loadingToday ? "Saving" : "Save"}
                              </button>
                            </div>
                            <p
                              style={{
                                marginTop: 10,
                                color: "green",
                                fontSize: "14px",
                                paddingBottom: -20,
                              }}>
                              {successTodays ? successTodays : ""}
                            </p>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}

                {loginUser &&
                  loginUser?.completed !== 1 &&
                  loginUser?.isClient && (
                    <div className="card mt-3">
                      <div className="card-body text-body">
                        <h5
                          style={{ color: "#1e2a42", marginBottom: 0 }}
                          className="fw-semibold">
                          Goal Weight (lbs)
                        </h5>
                        <form
                          className="d-flex flex-column h-100"
                          onSubmit={handleSubmitUpdate(updateData)}>
                          <div className="row align-items-end margin-top-mobile">
                            <FormTextField
                              divClassName="col-md-7"
                              innerDivClassName="col-sm-12"
                              labelClassName="col-12 fb-label pb-2"
                              label=""
                              fieldName="targetedWeight"
                              register={registerUpdate}
                              errors={errorsUpdate}
                              inputClass="form-control form-control-2"
                            />
                            <div className="col-md-5 mb-2 margin-top-mobile">
                              <button
                                disabled={loading}
                                type="submit"
                                className="btn btn-2 br-10 w-100">
                                {loading ? "Saving" : "Save"}
                              </button>
                            </div>
                            <p
                              style={{
                                marginTop: 10,
                                color: "green",
                                fontSize: "14px",
                                paddingBottom: -20,
                              }}>
                              {success ? success : ""}
                            </p>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}

                {/* <div className="card mb-3">
                  <h5 className="card-header pb-3 fw-semibold">
                    {dailyNotes?.title}
                  </h5>
                  <div
                    className="card-body text-body"
                    style={{
                      minHeight: 200,
                      maxHeight: 300,
                      overflowY: "scroll",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: dailyNotes?.notes,
                    }}></div>
                </div> */}

                {userWeights &&
                  userWeights.length > 0 &&
                  loginUser?.completed !== 1 &&
                  loginUser?.isClient && (
                    <div className="card mb-3 mt-3">
                      <h5 className="card-header pb-3 fw-semibold">Weight</h5>
                      <div className="card-body">
                        <div id="weight"></div>
                      </div>
                    </div>
                  )}

                {/* <div className="card mb-3">
                  <h5 className="card-header pb-3 fw-semibold">
                    Daily Dose Of Motivation
                  </h5>
                  <div className="card-body text-body">
                    <p>{dailyNotes?.dailyQuote?.quote}</p>

                    <p className="text-end text-1e">
                      - {dailyNotes?.dailyQuote?.source}
                    </p>
                  </div>
                </div> */}

                {loginUser &&
                  loginUser?.completed !== 1 &&
                  loginUser?.isClient && (
                    <div className="web-hide">
                      <a
                        role="button"
                        style={{
                          fontSize: 16,
                          fontWeight: 700,
                          color: "#fff",
                          width: "100%",
                          height: 90,
                        }}
                        onClick={() => {
                          if (loginUser?.isClient) {
                            history.push("/restartjourney");
                            window.scrollTo(0, 0);
                            localStorage.removeItem("high_fat_date");
                          } else {
                            window.open(
                              "https://alulawellness.com/product-category/breakthrough-m2/starter-packages/",
                            );
                          }
                        }}
                        className="btn btn-6 px-3">
                        <p style={{ marginTop: 10 }}>
                          Restart My <br /> Weight Loss Journey
                        </p>
                      </a>
                    </div>
                  )}
              </div>

              <div
                className={`col-md-${
                  loginUser?.completed || loginUser?.isClient ? 8 : 12
                }`}
                style={{ backgroundColor: "" }}>
                <div className="card mb-3">
                  {loginUser && !loginUser?.isClient && (
                    <p
                      style={{
                        fontSize: 18,
                        margin: "auto",
                        paddingTop: 45,
                        height: 120,
                      }}
                      className="card-header pb-3 fw-semibold">
                      Content will be displayed here once your program begins.{" "}
                      <b>Go to "My Journey" for your videos and next step. </b>
                    </p>
                  )}

                  {loginUser?.completed === 1 && (
                    <span
                      style={{
                        fontSize: 18,
                        height: 130,
                        paddingTop: 50,                        
                        marginBottom:350,
                        minHeight:550,
                        margin: "auto",
                      }}
                      className="card-header pb-4 fw-semibold">
                      <b>Congratulations! Welcome to Step 4 - Transition! </b>
                      <br/>
                      Click the My Journey tab for Step 3 and Step 4 instructions.
                      <br/>
Continue to track your progress in the 'Daily Stats' tab.


{loginUser?.completed === 1 && (
              <div
              className="col-lg-12 col-md- col-sm-6"
              style={{ position: "relative" }}>
                    <div className="card mb-3 mt-5" style={{backgroundColor:'#eee'}}>
                    <h6
                      className="card-header pb-3 fw-semibold"
                      style={{
                        fontSize: 20,
                        fontWeight: "bolder",
                        textAlign: "center",
                        color: "#088eac",
                        marginBottom: -10,
                      }}>
                      Share Your Success!
                    </h6>
                    <div className="row">
                      <div className="col-6 card-body tooltip-side">
                        {/* <span className="tooltiptext-success" style={{textAlign:'left', margin:'0 auto'}}>
                        Now that you have met your goal, do you want to scream it from the rooftops!? 
                        <br/>
                        The Breakthrough M2 Success Scream is an opportunity for you to have an interview with Nan about your amazing journey on the Breakthrough program. 
                        <br/>
                        At the end of the online interview via Zoom, you get to scream loud and proud about what you have accomplished! 
                        <br/>
                        This is a time for Breakthrough to celebrate with you and for you to share and inspire others about what is possible in the name of wellness!
                        </span> */}
                        <a
                          role="button"
                          style={{ fontSize: 14, color: "#fff" }}
                          onClick={() => {
                            setReferFriend(true);
                            setModalType("Schedule Success Scream Interview!");
                          }}
                          className="btn px-3">
                          Schedule Success Scream Interview!
                        </a>
                      </div>
                      <div
                        className="col-6 card-body"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}>
                        <a
                          role="button"
                          style={{
                            fontSize: 13,
                            color: "#fff",
                          }}
                          onClick={() => {
                            setTestimonial(true);
                          }}
                          className="btn px-3">
                          Submit Testimonial 
                          <br />& Before/ Afters
                        </a>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}>
                      <div className="col-6 card-body ">
                        <a
                          role="button"
                          style={{
                            fontSize: 14,
                            color: "#fff",
                            minWidth: 150,
                          }}
                          onClick={() => {
                            window.open("https://bit.ly/3vfsVGL");
                          }}
                          className="btn px-3">
                          Google Review
                        </a>
                      </div>
                      <div
                        className="col-6 card-body"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}>
                        <a
                          role="button"
                          style={{
                            fontSize: 14,
                            color: "#fff",
                          }}
                          onClick={() => {
                            window.open(
                              "https://www.facebook.com/BreakthroughM2Weightloss/reviews",
                            );
                          }}
                          className="btn px-3">
                          Facebook Review
                        </a>
                      </div>
                    </div>
                    </div>
                    </div>
                  )}
                    </span>

                  )}

                  {/* <div className="row">
                    {loginUser?.completed === 0 &&
                      loginUser?.completedDays > 0 &&
                      dailyNotes?.embed_code && (
                        <div className="col-md-4">
                          <h5 className="card-header pb-3 fw-semibold">
                            Today’s Video
                          </h5>
                          <div
                            className="aspect-ratio"
                            dangerouslySetInnerHTML={{
                              __html: dailyNotes?.embed_code,
                            }}></div>
                        </div>
                      )}

                    <div
                      className={`col-md-${
                        dailyNotes?.embed_code ? "8" : "12"
                      }`}>
                      {loginUser?.completed === 0 &&
                        loginUser?.completedDays > 0 &&
                        dailyNotes?.notes && (
                          <>
                            <h5 className="card-header pb-3 fw-semibold">
                              {dailyNotes?.title}
                            </h5>
                            <div
                              className="card-body text-body"
                              style={{ fontSize: 15 }}
                              dangerouslySetInnerHTML={{
                                __html: dailyNotes?.notes,
                              }}></div>
                            {dailyNotes?.dailyQuote?.quote && (
                              <div
                                className="card-body text-body"
                                style={{ fontSize: 15 }}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    '"' +
                                    dailyNotes?.dailyQuote?.quote +
                                    '"     ~ ' +
                                    dailyNotes?.dailyQuote?.source +
                                    "<br/><br/>" +
                                    dailyNotes?.notes,
                                }}></div>
                            )}

                            {!dailyNotes?.dailyQuote?.quote && (
                              <div
                                className="card-body text-body"
                                style={{ fontSize: 15 }}
                                dangerouslySetInnerHTML={{
                                  __html: dailyNotes?.notes,
                                }}></div>
                            )}
                          </>
                        )}
                    </div>
                  </div> */}
                </div>
              </div>
              {loginUser?.completed === 1 && (
                <div className="col-md-4 card-body">
                  <a
                    role="button"
                    style={{
                      fontSize: 16,
                      fontWeight: 700,
                      color: "#fff",
                      width: "100%",
                    }}
                    onClick={() => {
                      if (loginUser?.isClient) {
                        history.push("/restartjourney");
                        localStorage.removeItem("high_fat_date");
                      } else {
                        window.open(
                          "https://alulawellness.com/product-category/breakthrough-m2/starter-packages/",
                        );
                      }
                    }}
                    className="btn btn-6 px-3">
                    Restart My <br /> Weight Loss Journey
                  </a>
                </div>
              )}
            </div>
            
            {loginUser && loginUser?.completed !== 1 && loginUser?.isClient && (
              <div className="row">
                <div className="col-md-4 mobile-hide">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <a
                      role="button"
                      style={{
                        bottom: 0,
                        height: 90,
                        fontSize: 16,
                        color: "#fff",
                        width: "100%",
                        pointerEvents:
                          referralData?.affiliate_code_requested?.[0] ===
                            "TRUE" && "none",
                        opacity:
                          referralData?.affiliate_code_requested?.[0] ===
                            "TRUE" && 0.65,
                      }}
                      onClick={() => {
                        setReferFriend(true);
                        setModalType("Get Personal Affiliate Code");
                      }}
                      className="btn btn-4 px-3">
                      <p style={{ marginTop: 10 }}>
                        Get Personal <br /> Affiliate Code
                      </p>
                    </a>

                    {referralData?.affiliate_code && (
                      <span
                        className="mt-3"
                        style={{
                          color: "#333",
                          textAlign: "left",
                          fontWeight: 600,
                        }}>
                        Affiliate Code: {referralData?.affiliate_code}
                      </span>
                    )}
                    {referralData &&
                      referralData?.affiliate_code_requested?.[0] === "TRUE" &&
                      !referralData?.affiliate_code && (
                        <span
                          className="mt-3"
                          style={{
                            color: "#333",
                            textAlign: "left",
                            fontWeight: 600,
                          }}>
                          Request sent
                        </span>
                      )}
                  </div>
                </div>
                <div className="col-md-4 mobile-hide">
                  <div
                    style={{
                      width: "100%",
                      cursor: "pointer",
                      borderRadius: "50px",
                      height: 90,
                    }}
                    className="card gradient1 text-white mb-3"
                    onClick={() => setIsOpen(true)}>
                    <div
                      className="card-body text-center"
                      style={{ padding: "0.8rem 1rem" }}>
                      <h5 className="card-header fw-semibold p-0 text-uppercase text-white">
                        <i className="bx bx-error h5 mb-0"></i> Cheated?
                      </h5>
                      <p className="mb-0 mt-2">
                        Considering cheating? <br />
                        What's it worth?
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mobile-hide">
                  <a
                    role="button"
                    style={{
                      fontSize: 16,
                      fontWeight: 700,
                      color: "#fff",
                      width: "100%",
                      height: 90,
                    }}
                    onClick={() => {
                      if (loginUser?.isClient) {
                        history.push("/restartjourney");
                        window.scrollTo(0, 0);
                        localStorage.removeItem("high_fat_date");
                      } else {
                        window.open(
                          "https://alulawellness.com/product-category/breakthrough-m2/starter-packages/",
                        );
                      }
                    }}
                    className="btn btn-6 px-3">
                    <p style={{ marginTop: 10 }}>
                      Restart My <br /> Weight Loss Journey
                    </p>
                  </a>
                </div>
              </div>
            )}

            
          </div>
        </div>
        
      </div>
      
      <CheatedModal
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        openCoachCalendly={openCoachCalendly}
        closeModal={() => setIsOpen(false)}
      />
      <ValidationModal
        modalIsOpen={validationModal}
        setIsOpen={setValidationModal}
        history={history}
        closeModal={() => setIsOpen(false)}
      />
      <ReferFriend
        modalIsOpen={referFriend}
        setIsOpen={setReferFriend}
        modalType={modalType}
        closeModal={() => setReferFriend(false)}
      />
      <TestimonialModal
        modalIsOpen={testimonial}
        setIsOpen={setTestimonial}
        closeModal={() => setTestimonial(false)}
      />
      <CoachCalendly
        modalIsOpen={coachCalendly}
        setIsOpen={setCoachCalendly}
        closeModal={() => setCoachCalendly(false)}
      />
      <VideoTutorial
        modalIsOpen={videoTutorial}
        closeModal={() => {
          dispatch(getUserProfile());
          setVideoTutorial(false);
        }}
      />
      <div ref={messagesEndRef} />
    </>
  );
};

export default Home;
