import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getReferralData,
  getUserCoupons,
  referFriendHandler,
  sendRedeem,
} from "../../store/actions/dashboardAction";
import ReferFriend from "../../modals/refer-friend-modal";
import FormTextField from "../../forms/FormTextField";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { inputValidator } from "../../utils/functions";

const schema = yup.object({
  user_first_name: yup.string().trim().required("Cannot be empty"),
  user_last_name: yup.string().trim().required("Cannot be empty"),
  user_email: yup
    .string()
    .trim()
    .required("Cannot be empty")
    .matches(inputValidator.emailRegx, "Invalid Email"),
  user_phone: yup
    .string()
    .trim()
    .required("Cannot be empty")
    .matches(inputValidator.phoneRegx, "Invalid Number"),
});

const schemaRedeem = yup.object({
  paypal_phone: yup.string().trim().required("Cannot be empty"),
  venmo_username: yup.string().trim().required("Cannot be empty"),
  venmo_phone: yup.string().trim().required("Cannot be empty"),
  coupon_email: yup.string().trim().required("Cannot be empty"),
  house_number: yup.string().trim().required("Cannot be empty"),
  town: yup.string().trim().required("Cannot be empty"),
  state: yup.string().trim().required("Cannot be empty"),
  zip: yup.string().trim().required("Cannot be empty"),
});

const Referral = ({ loginUser }) => {
  const [referFriend, setReferFriend] = useState(false);
  const [modalType, setModalType] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingRedeem, setLoadingRedeem] = useState(false);
  const [redeemType, setRedeemType] = useState("");
  const [coupons, setCoupons] = useState([]);
  const [error, setError] = useState("");
  const [redeemError, setRedeemError] = useState("");

  const dispatch = useDispatch();

  const { referralData, userCoupons } = useSelector((state) => state.dashboard);
  const { isLoading } = useSelector((state) => state.ui);

  useEffect(() => {
    dispatch(getReferralData());
    dispatch(getUserCoupons())
  }, []);

  useEffect(() => {
    if (referralData?.preference_type) {
      setRedeemType(referralData?.preference_type);
    }
  }, [referralData]);

  useEffect(() => {    
    console.log('got usercoupons in effect', userCoupons)
      setCoupons(userCoupons);    
  }, [userCoupons]);  

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    register: registerRedeem,
    formState: { errors: errorsRedeem },
    reset: resetRedeem,
    trigger,
    getValues,
  } = useForm({
    resolver: yupResolver(schemaRedeem),
  });

  const saveData = async (data) => {
    setError("");

    const payload = {
      ...data,
      client_email: loginUser?.useremail,
    };

    setLoading(true);
    const res = await dispatch(referFriendHandler(payload));

    if (res.status === "success") {
      setReferFriend(true);
      setModalType("Refer Friend");
      reset();
    }
    setLoading(false);
  };

  const sendRedeemReq = async () => {
    const triggerVal = [];

    if (redeemType === "paypal") {
      triggerVal.push("paypal_phone");
    } else if (redeemType === "venmo") {
      triggerVal.push("venmo_username");
      triggerVal.push("venmo_phone");
    } else if (redeemType === "coupon") {
      triggerVal.push("coupon_email");
    } else {
      triggerVal.push("house_number");
      triggerVal.push("apartment");
      triggerVal.push("town");
      triggerVal.push("state");
      triggerVal.push("zip");
    }

    const result = await trigger(triggerVal);
    if (!result) {
      return false;
    }

    if (+referralData?.credit_amount <= -1) {
      setRedeemError("Referral credit is 0");
      setTimeout(() => {
        setRedeemError("");
      }, 3000);
      return;
    } else {
      setRedeemError("");
    }

    let preference_value = "";

    if (redeemType === "paypal") {
      preference_value = getValues("paypal_phone");
    } else if (redeemType === "venmo") {
      preference_value = `${getValues("venmo_username")}, ${getValues(
        "venmo_phone",
      )}`;
    } else if (redeemType === "coupon") {
      preference_value = getValues("coupon_email");
    } else {
      preference_value = `${getValues("house_number")}, ${getValues(
        "apartment",
      )}, ${getValues("town")}, ${getValues("state")}, ${getValues("zip")} `;
    }

    const payload = {
      preference_type: redeemType,
      preference_value,
    };

    setLoadingRedeem(true);
    await dispatch(sendRedeem(payload));
    setReferFriend(true);
    setModalType("Redeem");
    resetRedeem();
    setLoadingRedeem(false);
  };

  return (
    <div className="container-xxl flex-grow-1 container-p-b">
      {loginUser?.isClient ? (
        <div
          className="col-md-12 card shadow-none main-card"
          style={{ minHeight: "100vh" }}>
          <div className="row g-3">
            <div className="col-md-1"></div>
            <div
              className="col-md-10 card-body text-body card"
              style={{
                fontSize: 16,
                textAlign: "center",
                marginTop: 60,
                padding: 40,
              }}>
              <p
                style={{
                  color: "#333",
                  fontSize: 18,
                  marginBottom: 10,
                }}>
                What is the Breakthrough M2 Referral Program?
              </p>
              <p
                className="text-muted"
                style={{ color: "#333", textAlign: "left", fontSize: 16 }}>
                At Breakthrough M2, we love word-of-mouth marketing! We
                appreciate when you pay good health and wellness forward, and
                when you do, we love paying it forward to you! When you refer
                someone to our program and they become an M2 member, you'll
                receive a $20 referral credit and your person will receive a $10
                discount. To ensure they get their discount, please tell them to
                use your personal M2 Affiliate code.
              </p>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}>
                {referralData && (
                  <button
                    style={{ width: 300, marginTop: 20 }}
                    onClick={() => {
                      setReferFriend(true);
                      setModalType("Get Personal Affiliate Code");
                    }}
                    disabled={
                      referralData?.affiliate_code_requested?.[0] === "TRUE"
                    }
                    className="btn br-10">
                    Get Personal Affiliate Code
                  </button>
                )}

                {referralData?.affiliate_code && (
                  <span
                    className="mt-3"
                    style={{
                      color: "#333",
                      textAlign: "left",
                      fontWeight: 600,
                    }}>
                    Affiliate Code: {referralData?.affiliate_code}
                  </span>
                )}              

                {referralData &&
                  referralData?.affiliate_code_requested?.[0] === "TRUE" &&
                  !referralData?.affiliate_code && (
                    <span
                      className="mt-3"
                      style={{
                        color: "#333",
                        textAlign: "left",
                        fontWeight: 600,
                      }}>
                      Request sent
                    </span>
                  )}
                  
                  {(coupons && coupons.length > 0 ) && 
                <>
                  <span className="tag-val pt-4" style={{ fontSize: 18 }}>
                    Available Coupon Codes:
                  </span>
                  <span
                  className="mt-0"
                  style={{
                    color: "#333",
                    textAlign: "left",
                    fontWeight: 600,
                  }}>
                  {coupons.join(', ')}
                </span>
                </>
                }
                
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-1"></div>
            <div className="card col-md-10 mb-3">
              <div className="row justify-content-around">
                {isLoading ? (
                  <div
                    className="loading-text"
                    style={{ height: 80 }}>
                    <p>Loading...</p>
                  </div>
                ) : (
                  <>
                    <div className="col-lg-5 col-md-6 col-sm-12">
                      <div className="card-body text-body text-center">
                        <p className="text-1e h6 lh-base mb-2">
                          <span
                            className="tag-val"
                            style={{ fontSize: 18 }}>
                            Referrals:
                          </span>{" "}
                          <br />{" "}
                          {referralData ? referralData?.total_referrals : ""}{" "}
                        </p>
                      </div>
                      <div
                        style={{
                          border: "1px solid #333",
                          borderRadius: 5,
                        }}>
                        <h5
                          className="d-flex alight-items-center justify-content-center"
                          style={{
                            paddingTop: 20,
                            marginBottom: 0,
                            color: "#333",
                            fontWeight: 600,
                          }}>
                          Refer A Friend
                        </h5>
                        <form
                          className="d-flex flex-column h-100"
                          onSubmit={handleSubmit(saveData)}
                          style={{ padding: 20, paddingTop: 0 }}>
                          <div>
                            <FormTextField
                              divClassName="col-md-12"
                              innerDivClassName="col-md-12"
                              label="First Name"
                              fieldName="user_first_name"
                              register={register}
                              errors={errors}
                            />
                            <FormTextField
                              divClassName="col-md-12 mt-3"
                              innerDivClassName="col-md-12"
                              label="Last Name"
                              fieldName="user_last_name"
                              register={register}
                              errors={errors}
                            />
                            <FormTextField
                              divClassName="col-md-12 mt-3"
                              innerDivClassName="col-md-12"
                              label="Email"
                              fieldName="user_email"
                              register={register}
                              errors={errors}
                            />
                            <FormTextField
                              divClassName="col-md-12 mt-3"
                              innerDivClassName="col-md-12"
                              label="Phone"
                              fieldName="user_phone"
                              register={register}
                              errors={errors}
                            />
                          </div>

                          <div className="mt-4 d-flex justify-content-center align-items-center">
                            <button
                              className="btn w-50 btn-lg br-10"
                              type="submit"
                              disabled={loading}>
                              {loading ? "Processing" : "Submit"}
                            </button>
                          </div>
                          <span
                            className="mt-3"
                            style={{
                              color: "#333",
                              textAlign: "left",
                              fontWeight: 600,
                              color: "red",
                              textAlign: "center",
                            }}>
                            {error ? error : ""}
                          </span>
                        </form>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-6 col-sm-12">
                      <div className="card-body text-body text-center">
                        <p className="text-1e h6 lh-base mb-2">
                          <span
                            className="tag-val"
                            style={{ fontSize: "18px" }}>
                            Credit:{" "}
                          </span>{" "}
                          <br />{" "}
                          {referralData
                            ? `$${referralData?.credit_amount}`
                            : ""}{" "}
                        </p>
                      </div>
                      <div
                        style={{
                          border: "1px solid #333",
                          borderRadius: 5,
                          paddingTop: 20,
                          paddingBottom: 20,
                          height: "calc(100% - 110px)",
                        }}>
                        <h5
                          className="d-flex justify-content-center align-items-center"
                          style={{
                            marginBottom: 10,
                            color: "#333",
                            fontWeight: 600,
                          }}>
                          Redeem
                        </h5>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                            paddingLeft: 40,
                          }}>
                          <p className="text-1e h6 lh-base mb-1">
                            How would you like to redeem this credit?
                          </p>
                          <p className="text-1e h6 lh-base mb-1">
                            <b>
                              *Choose "Coupon" to upgrade your credit to $25!
                            </b>
                          </p>
                          <br />
                          <div className="form-check mt-2">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="redeem"
                              id="flexRadioDefault1"
                              checked={redeemType === "paypal"}
                              onChange={() => {
                                resetRedeem();
                                setRedeemType("paypal");
                              }}
                            />
                            <label
                              className="form-check-label-redeem"
                              for="flexRadioDefault1"
                              style={{ fontSize: 16 }}>
                              PayPal
                            </label>
                          </div>
                          {redeemType === "paypal" && (
                            <div className="redeem-box margin-top-mobile-10">
                              <FormTextField
                                divClassName="col-md-12"
                                innerDivClassName="col-md-12"
                                label=""
                                placeholder="Paypal Phone"
                                fieldName="paypal_phone"
                                register={registerRedeem}
                                errors={errorsRedeem}
                              />
                            </div>
                          )}
                          <div className="form-check mt-2">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="redeem"
                              id="flexRadioDefault2"
                              checked={redeemType === "venmo"}
                              onChange={() => {
                                resetRedeem();
                                setRedeemType("venmo");
                              }}
                            />
                            <label
                              className="form-check-label-redeem"
                              for="flexRadioDefault2"
                              style={{ fontSize: 16 }}>
                              Venmo
                            </label>
                          </div>

                          {redeemType === "venmo" && (
                            <>
                              <div className="redeem-box margin-top-mobile-10">
                                <FormTextField
                                  divClassName="col-md-12"
                                  innerDivClassName="col-md-12"
                                  label=""
                                  placeholder="Venmo Username"
                                  fieldName="venmo_username"
                                  register={registerRedeem}
                                  errors={errorsRedeem}
                                />
                              </div>
                              <div className="redeem-box margin-top-mobile-10">
                                <FormTextField
                                  divClassName="col-md-12"
                                  innerDivClassName="col-md-12"
                                  label=""
                                  placeholder="Venmo Phone (last 4 digits)"
                                  fieldName="venmo_phone"
                                  register={registerRedeem}
                                  errors={errorsRedeem}
                                />
                              </div>
                            </>
                          )}
                          <div className="form-check mt-2">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="redeem"
                              id="flexRadioDefault3"
                              checked={redeemType === "coupon"}
                              onChange={() => {
                                resetRedeem();
                                setRedeemType("coupon");
                              }}
                            />
                            <label
                              className="form-check-label-redeem"
                              for="flexRadioDefault3"
                              style={{ fontSize: 16 }}>
                              Coupon
                            </label>
                          </div>

                          {redeemType === "coupon" && (
                            <div className="redeem-box margin-top-mobile-10">
                              <FormTextField
                                divClassName="col-md-12"
                                innerDivClassName="col-md-12"
                                label=""
                                placeholder="Email to receive coupon"
                                fieldName="coupon_email"
                                register={registerRedeem}
                                errors={errorsRedeem}
                              />
                            </div>
                          )}

                          <div className="form-check mt-2">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="redeem"
                              id="flexRadioDefault4"
                              checked={redeemType === "bank_check"}
                              onChange={() => {
                                resetRedeem();
                                setRedeemType("bank_check");
                              }}
                            />
                            <label
                              className="form-check-label-redeem"
                              for="flexRadioDefault4"
                              style={{ fontSize: 16 }}>
                              Bank Check
                            </label>
                          </div>

                          {redeemType === "bank_check" && (
                            <>
                              <div className="redeem-box margin-top-mobile-10">
                                <FormTextField
                                  divClassName="col-md-12"
                                  innerDivClassName="col-md-12"
                                  label=""
                                  placeholder="House number and street name"
                                  fieldName="house_number"
                                  register={registerRedeem}
                                  errors={errorsRedeem}
                                />
                              </div>
                              <div className="redeem-box margin-top-mobile-10">
                                <FormTextField
                                  divClassName="col-md-12"
                                  innerDivClassName="col-md-12"
                                  label=""
                                  placeholder="Apartment, suite, etc. (optional)"
                                  fieldName="apartment"
                                  register={registerRedeem}
                                  errors={errorsRedeem}
                                />
                              </div>
                              <div className="redeem-box margin-top-mobile-10">
                                <FormTextField
                                  divClassName="col-md-12"
                                  innerDivClassName="col-md-12"
                                  label=""
                                  placeholder="Town/City"
                                  fieldName="town"
                                  register={registerRedeem}
                                  errors={errorsRedeem}
                                />
                              </div>
                              <div className="redeem-box margin-top-mobile-10">
                                <FormTextField
                                  divClassName="col-md-12"
                                  innerDivClassName="col-md-12"
                                  label=""
                                  placeholder="State"
                                  fieldName="state"
                                  register={registerRedeem}
                                  errors={errorsRedeem}
                                />
                              </div>
                              <div className="redeem-box margin-top-mobile-10">
                                <FormTextField
                                  divClassName="col-md-12"
                                  innerDivClassName="col-md-12"
                                  label=""
                                  placeholder="Zip"
                                  fieldName="zip"
                                  register={registerRedeem}
                                  errors={errorsRedeem}
                                />
                              </div>
                            </>
                          )}

                          <div className="d-flex justify-content-center align-items-center">
                            <button
                              className="btn w-50 btn-lg br-10 mt-4"
                              onClick={sendRedeemReq}
                              disabled={loadingRedeem}>
                              {loadingRedeem ? "Processing" : "Send Request"}
                            </button>
                          </div>
                          <span
                            className="mt-3"
                            style={{
                              color: "#333",
                              textAlign: "left",
                              fontWeight: 600,
                              color: "red",
                              textAlign: "center",
                            }}>
                            {redeemError || ""}
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="col-md-1"></div>
          </div>
        </div>
      ) : (
        <div className="card mb-3">
          <p
            style={{
              fontSize: 18,
              paddingTop: 45,
              height: 120,
              textAlign: "center",
            }}
            className="card-header pb-3 fw-semibold">
            Content will be displayed here once your program begins.
          </p>
        </div>
      )}
      <ReferFriend
        modalIsOpen={referFriend}
        setIsOpen={setReferFriend}
        modalType={modalType}
        closeModal={() => setReferFriend(false)}
      />
    </div>
  );
};

export default Referral;
