import React from "react";
import { useSelector } from "react-redux";

const BonusVideos = ({ loading, loginUser }) => {
  const { bonusVideosData } = useSelector((state) => state.dashboard);

  return (
    <div className="container-xxl flex-grow-1 container-p-b">
      {loginUser?.isClient ? (
        <div className="card shadow-none main-card">
          <div
            className="card-body"
            style={{ minHeight: "100vh" }}>
            <div className="bonus-video-container">
              <h2
                style={{
                  marginBottom: 0,
                  fontWeight: 600,
                  fontSize: 22,
                  color: "#566a7f",
                }}>
                Dashboard Walkthrough
              </h2>
              <div
                className="row"
                style={{ marginTop: 0 }}>
                <div
                  className="col-md-6"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}>
                  <iframe
                    src={
                      "https://dyhyg6r6fdehc.cloudfront.net/btm2/client-dashboard-walkthrough.mp4"
                    }
                    frameborder="0"
                    allow="fullscreen; picture-in-picture"
                    className="bounus-videos-iframe"
                    title="Step 4 - Healthy Habits &amp; What to Watch For"></iframe>
                </div>
              </div>
            </div>
            {!loading ? (
              bonusVideosData?.categoryData?.map((val, i) => (
                <>
                  {bonusVideosData?.data?.data?.filter(
                    (video) => video?.category?.id === val?.category_id,
                  )?.length > 0 && (
                    <div
                      className="bonus-video-container"
                      style={{
                        marginTop: 100,
                      }}>
                      <h2
                        style={{
                          marginBottom: 0,
                          fontWeight: 600,
                          fontSize: 22,
                          color: "#566a7f",
                        }}>
                        {val?.name}
                      </h2>
                      <div
                        className="row"
                        style={{ marginTop: 0 }}>
                        {bonusVideosData?.data?.data
                          ?.filter(
                            (video) => video?.category?.id === val?.category_id,
                          )
                          ?.map((video, videoIndex) => (
                            <div
                              key={videoIndex}
                              className="col-md-6"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}>
                              <iframe
                                src={`https://www.youtube.com/embed/${video?.youtube_id}`}
                                frameborder="0"
                                allow="autoplay; fullscreen; picture-in-picture"
                                className="bounus-videos-iframe"
                                title="Step 4 - Healthy Habits &amp; What to Watch For"></iframe>
                            </div>
                          ))}

                        {bonusVideosData?.data?.data?.filter(
                          (video) => video?.category?.id === val?.category_id,
                        )?.length === 0 && (
                          <p
                            className="mt-2"
                            style={{ textAlign: "center" }}>
                            No Videos
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                </>
              ))
            ) : (
              <h5 style={{ textAlign: "center", marginTop: 20 }}>Loading...</h5>
            )}
          </div>
        </div>
      ) : (
        <div className="card mb-3">
          <p
            style={{
              fontSize: 18,
              paddingTop: 45,
              height: 120,
              textAlign: "center",
            }}
            className="card-header pb-3 fw-semibold">
            Content will be displayed here once your program begins.
          </p>
        </div>
      )}
    </div>
  );
};

export default BonusVideos;
